import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, layout: 'LoginLayout' },
    children: [
      {
        path: 'register',
        name: `${routeNamePrefix}.register-tenant`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "tenant-registration" */ './views/register-tenant'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'activate',
        name: `${routeNamePrefix}.activate-tenant`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "tenant-registration" */ './views/activate-tenant'
            )
          ),
        meta: {
          public: true,
        },
      },
    ],
  },
]
