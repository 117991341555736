<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'SupportConsoleModule',
  page() {
    return {
      title: this.$t('support_console'),
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        vm.myAllowedModules.indexOf(
          'admin.organization.application_maintenance'
        ) === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.myAllowedModules.indexOf('admin') === -1) {
        return vm.$router.replace({ name: '404' })
      }
      return true
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.organization') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>
