<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
export default {
  name: 'TenantRegistrationModule',
  page() {
    return {
      title: 'Registration',
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   const hostname = window.location.hostname
  //   const subdomain = hostname.split('.')[0]
  //   const validSubdomains = ['localhost', 'register']
  //   if (validSubdomains.indexOf(subdomain) === -1) {
  //     return next({ name: '404' })
  //   }
  //   next()
  // },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
}
</script>
