<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'UserSessionsModule',
  page() {
    return {
      title: this.$t('user_sessions'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf('customDashboard') === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
    })
  },
  computed: {
    ...LicenseComputed,
  },
}
</script>
