<template>
  <FlotoConfirmModal
    open
    @hide="$emit('hide')"
    @confirm="$emit('confirm', { statusId: closeStatusId })"
  >
    <template v-slot:icon>
      <MIcon
        name="check-circle"
        class="w-full text-secondary-green"
        size="2x"
      />
    </template>
    <template v-slot:message>
      <slot name="message">
        {{
          $t('confirm_close', {
            resource: $tc(moduleName),
          })
        }}
        <!-- {{
        $t('confirm_close_related', {
          resource: $tc(moduleName),
          resources: $tc(moduleName, 2),
        })
      }} -->
      </slot>
    </template>
  </FlotoConfirmModal>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
export default {
  name: 'ConfirmCloseModal',
  props: {
    moduleName: { type: String, required: true },
  },
  computed: {
    ...StatusComputed,
    closeStatusId() {
      if (this[`${this.moduleName}FindStatus`]) {
        return this[`${this.moduleName}FindStatus`]('closed').id
      }
      return undefined
    },
  },
}
</script>
