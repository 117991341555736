<template>
  <FlotoDrawer width="60%" @show="$emit('trigger')" @hide="$emit('close')">
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"><span /></slot>
    </template>
    <template v-slot:title>
      {{ `${$tc(type)} ${$tc('transition')}: ${resource.name}` }}
    </template>
    <div class="flex flex-col h-full">
      <TransitionDetails
        v-if="type === 'status'"
        transition-type="status_changed"
        :module-name="moduleName"
        :resource-id="resource.id"
        :field-name="`${$tc('status')}`"
      />
      <template v-else-if="type === 'assignment'">
        <TransitionDetails
          transition-type="technician_changed"
          :module-name="moduleName"
          :resource-id="resource.id"
          :field-name="`${$tc('technician')}`"
        />
        <MDivider />
        <TransitionDetails
          transition-type="technician_group_changed"
          :module-name="moduleName"
          :resource-id="resource.id"
          :field-name="`${$tc('technician')} ${$tc('group')}`"
        />
      </template>
    </div>
    <template v-slot:actions="{ hide }">
      <MButton id="done-btn" variant="default" @click="hide">
        {{ $tc('done') }}
      </MButton>
    </template>
  </FlotoDrawer>
</template>

<script>
import TransitionDetails from './transition-details'
export default {
  name: 'TransitionDrawer',
  components: { TransitionDetails },
  props: {
    moduleName: { type: String, required: true },
    type: { type: String, required: true },
    resource: { type: Object, required: true },
  },
}
</script>
