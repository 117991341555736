<template>
  <a @click="triggerAction">
    <MIcon name="sync" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $tc('scenario') }}
    </span>
    <FlotoDrawer :open="showDrawer" @hide="showDrawer = false">
      <template v-slot:title>
        {{ title }}
      </template>
      <ScenarioList :module-name="moduleName" :resource-id="resourceId">
        <template v-slot:action="{ item }">
          <MButton
            id="execute-btn"
            size="small"
            outline
            @click="handleScenarioExecute(item.id)"
          >
            {{ $t('execute') }}
          </MButton>
        </template>
      </ScenarioList>
    </FlotoDrawer>
  </a>
</template>

<script>
import Bus from '@utils/emitter'
import { executeScenarioApi } from '@modules/scenario/scenario-api'
import ScenarioList from '@components/item-selection-list/scenario-list'

export default {
  name: 'Scenario',
  components: { ScenarioList },
  props: {
    title: { type: String, required: true },
    moduleName: { type: String, required: true },
    resourceId: { type: Number, required: true },
    bindShortcut: { type: Boolean, default: false },
  },
  data() {
    return {
      showDrawer: false,
    }
  },
  created() {
    if (this.bindShortcut) {
      const handler = this.triggerAction.bind(this)
      Bus.$on('scenario', handler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('scenario', handler)
      })
    }
  },
  methods: {
    triggerAction() {
      this.$emit('trigger')
      setTimeout(() => {
        this.showDrawer = true
      }, 400)
    },
    handleScenarioExecute(scenarioId) {
      executeScenarioApi(scenarioId, this.resourceId).then(() => {
        this.showDrawer = false
        this.$emit('done')
      })
    },
  },
}
</script>
