const stageTabMap = {
  submitted: ['approval'],
  planning: ['approval'],
  approval: ['change_activity', 'task', 'relation', 'field_update'],
  implementation: ['change_activity', 'approval'],
  build: ['change_activity', 'approval'],
  testing: ['change_activity', 'approval'],
  deployment: ['change_activity', 'approval'],
  review: ['change_activity', 'task', 'approval', 'relation', 'field_update'],
}

export const getDisabledTabsForStage = (stage) => {
  return stageTabMap[stage] || []
}

export const takeConfirmationOnStageChange = ['completed', 'accepted']
