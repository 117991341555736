var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,class:{
    'active-cursor': _vm.index === _vm.currentCursorPosition,
    'cursor-pointer': _vm.view === 'split',
    'active-item':
      _vm.selectedItemIds.indexOf(_vm.item.id) >= 0 ||
      (_vm.activeItem || {}).id === _vm.item.id,
  },attrs:{"data-index":_vm.index},on:{"click":function($event){return _vm.onRowClick(_vm.item)}}},[(_vm.selectable)?_c('td',{staticClass:"checkbox",staticStyle:{"z-index":"0"}},[_c('MCheckbox',{attrs:{"checked":_vm.selectedItemIds.indexOf(_vm.item.id) >= 0},on:{"click":function($event){$event.stopPropagation();return (function (e) { return e; }).apply(null, arguments)},"change":function($event){return _vm.toggleSelectItem(_vm.item)}}})],1):_vm._e(),_vm._l((_vm.columns),function(column,columnIndex){return _c('td',{key:column.key,class:column.class,style:(_vm.view !== 'split' && _vm.columnWidths[columnIndex]
        ? { width: ((_vm.columnWidths[columnIndex]) + "px") }
        : {}),attrs:{"align":column.align}},[(column.key === 'subject')?_c('ResourceListTitle',_vm._b({attrs:{"name":_vm.item.name,"subject":_vm.item.subject,"external-link":_vm.view === 'list',"internal-link":_vm.view === 'list',"link":_vm.item.archived === false
          ? _vm.$modules.getModuleRoute('ticket', 'view', {
              params: {
                id: _vm.item.id,
                serviceCatalogId: _vm.item.serviceCatalogId,
                ticketType: _vm.moduleName,
              },
            })
          : undefined}},'ResourceListTitle',
        _vm.view === 'split'
          ? {
              requesterName: _vm.item.requesterData.name,
              requesterEmail: _vm.item.requesterData.email,
              createdAt: _vm.item.createdAt,
            }
          : {}
      ,false)):(column.key === 'requesterId')?[_c('FlotoUserDrawer',{attrs:{"user":_vm.item.requesterData}})]:(column.key === 'createdTime')?[_c('div',{staticClass:"page-secondary-text-color text-ellipsis"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.createdAt))+" ")])]:(column.key === 'technicianId')?[_c('FlotoTechnicianPicker',{attrs:{"active-technicians":_vm.activeTechniciansContext.activeTechnicians,"value":_vm.item.technicianId,"module-name":_vm.moduleName,"group-id":_vm.item.groupId,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived,"mandatory-selection":_vm.isRequiredFn('technicianId')},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            technicianId: $event,
          })}}})]:(column.key === 'statusId')?[_c('FlotoStatusPicker',{attrs:{"value":_vm.item.statusId,"module-name":_vm.moduleName,"disabled":_vm.disabled ||
          _vm.item.statusId === _vm.closedStatusId ||
          _vm.item.archived ||
          _vm.moduleName === _vm.$constants.CHANGE ||
          _vm.moduleName === _vm.$constants.RELEASE},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            statusId: $event,
            hasSecondaryTickets: _vm.item.hasSecondaryTickets,
          })}}})]:(column.key === 'priorityId')?[_c('FlotoPriorityPicker',{attrs:{"value":_vm.item.priorityId,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            priorityId: $event,
          })}}})]:(column.key === 'dueBy')?[(_vm.item.dueBy < 0)?[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.oldDueBy))+" ")]:[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.dueBy))+" ")]]:(column.key === 'responseDue')?[(_vm.item.responseDue >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.responseDue))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("datetime")(_vm.item.oldResponseDue)))])]:(column.key === 'affectedServices')?[_c('FlotoBusinessServicePicker',{attrs:{"value":_vm.item.affectedServices,"placeholder":"---","as-input":"","multiple":"","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            affectedServices: $event || null,
          })}}})]:(column.key === 'resolutionDueLevel')?[_c('FlotoDueDatePicker',{attrs:{"value":_vm.item.dueBy,"show-icon":false,"disabled":"","module-name":_vm.moduleName,"status-id":_vm.item.statusId}})]:(column.key === 'groupId')?[_c('div',{staticClass:"flex items-center"},[_c('FlotoTechnicianGroupPicker',{attrs:{"value":_vm.item.groupId,"as-input":false,"suggested-value":(_vm.suggestedValues || {}).suggestedGroupId,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived,"mandatory-selection":_vm.isRequiredFn('groupId')},on:{"change":function($event){return _vm.update({
              id: _vm.item.id,
              serviceCatalogId: _vm.item.serviceCatalogId,
              groupId: $event,
            })}}})],1)]:(column.key === 'urgencyId')?[_c('FlotoUrgencyPicker',{attrs:{"value":_vm.item.urgencyId,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            urgencyId: $event,
          })}}})]:(column.key === 'createdById')?[_c('FlotoUserDrawer',{attrs:{"user-id":_vm.item.createdBy}})]:(column.key === 'impactId')?[_c('FlotoImpactPicker',{attrs:{"value":_vm.item.impactId,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            impactId: $event,
          })}}})]:(column.key === 'categoryId')?[_c('div',{staticClass:"flex items-center"},[_c('FlotoCategoryPicker',{attrs:{"value":_vm.item.categoryId,"module-name":_vm.moduleName,"placeholder":"---","as-input":false,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived,"suggested-value":(_vm.suggestedValues || {}).suggestedCategoryId},on:{"change":function($event){return _vm.update({
              id: _vm.item.id,
              serviceCatalogId: _vm.item.serviceCatalogId,
              categoryId: $event,
            })}}})],1)]:(column.key === 'departmentId')?[_c('div',{staticClass:"flex items-center"},[_c('FlotoDepartmentPicker',{attrs:{"value":_vm.item.departmentId,"as-input":false,"placeholder":"---","module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
              id: _vm.item.id,
              serviceCatalogId: _vm.item.serviceCatalogId,
              departmentId: $event,
            })}}})],1)]:(column.key === 'companyId')?[_c('CompanyPicker',{attrs:{"value":_vm.item.companyId,"module-name":_vm.moduleName,"as-input":false,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            companyId: $event,
          })}}})]:(column.key === 'sourceId')?[_c('FlotoSourcePicker',{attrs:{"value":_vm.item.sourceId,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            sourceId: $event,
          })}}})]:(column.key === 'locationId')?[_c('div',{staticClass:"flex items-center"},[_c('FlotoLocationPicker',{attrs:{"value":_vm.item.locationId,"placeholder":"---","as-input":false,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
              id: _vm.item.id,
              serviceCatalogId: _vm.item.serviceCatalogId,
              locationId: $event,
            })}}})],1)]:(column.key === 'supportLevel')?[(_vm.item.supportLevel)?_c('FlotoSupportLevelPicker',{attrs:{"value":_vm.item.supportLevel,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            supportLevel: $event,
          })}}}):_c('span',[_vm._v("---")])]:(column.key === 'natureOfProblem')?[_c('FlotoDropdownPicker',{attrs:{"value":_vm.item.natureOfProblem,"options":[
          { text: _vm.$t('proactive'), key: 'proactive' },
          { text: _vm.$t('reactive'), key: 'reactive' } ],"as-input":"","placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            natureOfProblem: $event || null,
          })}}})]:(column.key === 'knownError')?[_vm._v(" "+_vm._s(_vm.$t(_vm.item.knownError === 'true' ? 'yes' : 'no'))+" ")]:(column.key === 'business-service')?[_c('FlotoBusinessServicePicker',{attrs:{"value":_vm.item.businessServiceId,"placeholder":"---","as-input":"","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            businessServiceId: $event || null,
          })}}})]:(
        column.key === 'riskTypeId' && _vm.moduleName === _vm.$constants.CHANGE
      )?[_c('FlotoRiskTypePicker',{attrs:{"value":_vm.item.riskTypeId,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            riskTypeId: $event || null,
          })}}})]:(
        column.key === 'riskTypeId' && _vm.moduleName === _vm.$constants.RELEASE
      )?[_c('ReleaseRiskTypePicker',{attrs:{"value":_vm.item.riskTypeId,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            riskTypeId: $event || null,
          })}}})]:(column.key === 'changeTypeId')?[_c('FlotoChangeTypePicker',{attrs:{"value":_vm.item.changeTypeId,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            changeTypeId: $event || null,
          })}}})]:(column.key === 'releaseTypeId')?[_c('ReleaseTypePicker',{attrs:{"value":_vm.item.releaseTypeId,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            releaseTypeId: $event || null,
          })}}})]:(
        column.key === 'reasonType' && _vm.moduleName === _vm.$constants.CHANGE
      )?[_c('FlotoReasonTypePicker',{attrs:{"value":_vm.item.reasonType,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            reasonType: $event || null,
          })}}})]:(
        column.key === 'reasonType' && _vm.moduleName === _vm.$constants.RELEASE
      )?[_c('ReleaseReasonTypePicker',{attrs:{"value":_vm.item.reasonType,"placeholder":"---","disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            reasonType: $event || null,
          })}}})]:(column.key === 'changeImplementor')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.changeImplementor,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            changeImplementor: $event,
          })}}})]:(column.key === 'releaseEngineer')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.releaseEngineer,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            releaseEngineer: $event,
          })}}})]:(column.key === 'changeManager')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.changeManager,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            changeManager: $event,
          })}}})]:(column.key === 'releaseManager')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.releaseManager,"module-name":_vm.moduleName,"disabled":_vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            releaseManager: $event,
          })}}})]:(column.key === 'changeReviewer')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.changeReviewer,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            changeReviewer: $event,
          })}}})]:(column.key === 'releaseReviewer')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.releaseReviewer,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            releaseReviewer: $event,
          })}}})]:(column.key === 'qaManager')?[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.item.qaManager,"module-name":_vm.moduleName,"disabled":_vm.disabled || _vm.item.statusId === _vm.closedStatusId || _vm.item.archived},on:{"change":function($event){return _vm.update({
            id: _vm.item.id,
            serviceCatalogId: _vm.item.serviceCatalogId,
            qaManager: $event,
          })}}})]:(column.key === 'updatedTime')?[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.updatedAt))+" ")]:(
        column.key === 'startDate' && _vm.moduleName === _vm.$constants.RELEASE
      )?[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.startDate))+" ")]:(
        column.key === 'endDate' && _vm.moduleName === _vm.$constants.RELEASE
      )?[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.endDate))+" ")]:(column.key === 'lastResolvedTime')?[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.item.lastResolvedTime))+" ")]:(column.key === 'lastClosedTime')?[_vm._v(" "+_vm._s(_vm._f("datetime")(!_vm.item.lastClosedTime ? '---' : _vm.item.lastClosedTime))+" ")]:(column.key === 'lastApprovedDate')?[_vm._v(" "+_vm._s(_vm._f("datetime")(!_vm.item.lastApprovedDate ? '---' : _vm.item.lastApprovedDate))+" ")]:(column.key === 'ticketAge')?[(_vm.item.ticketAge)?_c('FlotoDueDatePicker',{attrs:{"value":_vm.item.ticketAge,"disabled":"","module-name":_vm.moduleName,"is-show-age":"","icon-size":"2x"}}):_c('span',[_vm._v("---")])]:(column.key === 'approvalStatus')?[(_vm.item.approvalStatus)?_c('span',[_vm._v(_vm._s(_vm.$tc(_vm.item.approvalStatus)))]):_c('span',[_vm._v("---")])]:(column.key === 'closedBy')?[(_vm.item.closedBy)?_c('FlotoUserDrawer',{attrs:{"user-id":_vm.item.closedBy}}):_c('span',[_vm._v("---")])]:(column.key === 'resolvedBy')?[(_vm.item.resolvedBy)?_c('FlotoUserDrawer',{attrs:{"user-id":_vm.item.resolvedBy}}):_c('span',[_vm._v("---")])]:(column.key === 'tags')?[_vm._v(" "+_vm._s((_vm.item.tags || []).length ? _vm.item.tags.join(', ') : '---')+" ")]:(/^\d+$/.test(column.key))?[_c('ListTableCustomFieldValue',{attrs:{"module-name":_vm.moduleName,"column":column,"item":_vm.item}})]:[_vm._v(" Column Slot Missing! ")]],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }