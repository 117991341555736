<template>
  <AdditionalInfoExpandable
    :key="renderCountForAdditionalInfo"
    :module-name="moduleName"
  >
    <MRow :gutter="16">
      <MCol
        :class="{
          'bg-neutral-lightest': giveExternalUpdateOptoin,
          'py-2 px-6': giveExternalUpdateOptoin,
          rounded: giveExternalUpdateOptoin,
        }"
      >
        <MRow>
          <MCol
            v-if="giveExternalUpdateOptoin"
            :size="12"
            style="justify-content: flex-end"
            class="flex mb-1"
          >
            <MButton
              :disabled="disabled"
              variant="neutral-light"
              class="mx-1"
              :shadow="false"
              shape="circle"
              @click="handleEditCustomField"
            >
              <MIcon name="pencil" />
            </MButton>
          </MCol>
          <MCol :size="`${!giveExternalUpdateOptoin ? '9' : '12'}`">
            <MCollapse
              :bordered="false"
              default-active-key="all"
              class="flex-1 size-small"
            >
              <MCollapsePanel v-for="stage in availabelStages" :key="stage.key">
                <template v-slot:header>
                  <h6 class="mb-0 text-primary md-text-sm">
                    {{ stage.text }}
                  </h6>
                </template>
                <MRow>
                  <MCol>
                    <AdditionalInfo
                      :stage="stage.key"
                      :disabled="isFormRulesAvailable || isDisabled(stage.key)"
                      :is-form-rules-available="isFormRulesAvailable"
                      :module-name="moduleName"
                      :update-fn="handleUpdateMoreDetails"
                      :resource="resource"
                      v-bind="$attrs"
                    />
                  </MCol>
                </MRow>
              </MCollapsePanel>
            </MCollapse>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
  </AdditionalInfoExpandable>
</template>

<script>
import { StatusComputed } from '@state/modules/status'
import { FormComputed } from '@state/modules/form'
import IsEqual from 'lodash/isEqual'
import Pick from 'lodash/pick'
import GroupBy from 'lodash/groupBy'
import { changeStages, releaseStages } from '@utils/status'
import AdditionalInfo from '@components/additional-information/additional-info'
import AdditionalInfoExpandable from '@components/additional-information/additional-info-expandable'
import {
  executionTypeMap,
  userTypeMap,
  eventTypeMap,
  formRulesSupportedModules,
} from '@data/form-rules'
import { getGlobalUserApi } from '@modules/users/users-api'

export default {
  name: 'ChangeFormConsumer',
  components: { AdditionalInfoExpandable, AdditionalInfo },
  inject: {
    formRulesContext: {
      default: () => ({
        fieldRulesState: {},
      }),
    },
  },
  props: {
    disabled: { type: Boolean, default: false },
    isChangeDisabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    updateApi: { type: Function, default: undefined },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      requesterDetails: {},
      renderCountForAdditionalInfo: 1,
    }
  },
  computed: {
    ...FormComputed,
    ...StatusComputed,
    formRulesUserTypeFilter() {
      if (!this.loggedIn) {
        return [userTypeMap['all'], userTypeMap['requester']]
      }
      if (this.isPortalLogin) {
        return [
          userTypeMap['all'],
          userTypeMap['requester'],
          userTypeMap['logged_in_user'],
        ]
      }
      return [
        userTypeMap['all'],
        userTypeMap['technician'],
        userTypeMap['logged_in_user'],
      ]
    },
    isFormRulesAvailable() {
      if (
        formRulesSupportedModules.indexOf(this.moduleName) >= 0 &&
        (this.formRulesContext.hasTicketFormRules ||
          this.formRulesContext.hasServiceCatalogFormRules)
      ) {
        return true
      }
      return false
    },
    initialStages() {
      return [{ text: this.$t('all'), key: 'all' }].concat(
        this.stagesList.filter((s) => s.key !== 'approval')
      )
    },
    stagesList() {
      if (this.moduleName === this.$constants.RELEASE) {
        return releaseStages()
      }
      return changeStages()
    },
    availabelStages() {
      if (!this[`${this.moduleName}Fields`]) {
        throw new Error(`given module ${this.moduleName} is not found in store`)
      }
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        const fields = this[`${this.moduleName}Fields`]()
        const groupedFields = GroupBy(fields, 'stage')
        const stages = Object.keys(groupedFields).map((k) => k)
        return this.initialStages.filter((s) => stages.indexOf(s.key) >= 0)
      }
      return []
    },
    currentStage() {
      if (this.resource.statusId) {
        return (
          this[`${this.moduleName}FindStatusFromId`](this.resource.statusId) ||
          {}
        ).stage
      }
      return undefined
    },
    giveExternalUpdateOptoin() {
      return !this.disabled && this.isFormRulesAvailable
    },
  },
  watch: {
    resource: {
      immediate: true,
      handler: 'handleExectuteFormRules',
    },
  },
  methods: {
    handleEditCustomField() {
      if (!this.disabled) {
        this.$emit('edit-additional-info')
      }
    },
    handleExectuteFormRules(newValue) {
      if (this.isFormRulesAvailable && Object.keys(newValue).length) {
        const executeFn = (requesterDetails) => {
          this.formRulesContext.executeFormRules(newValue, {
            executionTypes: [
              executionTypeMap['create_and_edit'],
              executionTypeMap['edit'],
            ],
            userTypes: this.formRulesUserTypeFilter,
            eventType: eventTypeMap['load'],
            requesterDetails,
          })
        }
        if (Object.keys(this.requesterDetails).length) {
          executeFn(this.requesterDetails)
        } else {
          getGlobalUserApi(newValue.requesterId, { archived: true }).then(
            (data) => {
              this.requesterDetails = data
              executeFn(data)
            }
          )
        }
      }
      this.renderCountForAdditionalInfo++
    },
    handleUpdateMoreDetails(data) {
      const diff = Object.keys(data).filter(
        (k) => !IsEqual(this.resource.fieldValueDetails[k], data[k])
      )
      return this.updateApi({
        ...Pick(data, diff),
        fieldValueDetails: { ...this.resource.fieldValueDetails },
      })
    },
    isDisabled(stage) {
      if (this.disabled) {
        return true
      }
      if (stage === 'all' && !this.isChangeDisabled) {
        return false
      }
      if (stage === 'all' && this.currentStage === 'review') {
        return false
      }
      if (stage !== this.currentStage) {
        return true
      }
    },
  },
}
</script>
