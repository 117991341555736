import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}/:ticketType`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: `${routeNamePrefix}`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/list-view')
          ),
      },
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/create')
          ),
      },
      {
        path: 'feedback',
        meta: {
          public: true,
          layout: 'LoginLayout',
        },
        name: `${routeNamePrefix}.feedback`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/feedback-page')
          ),
      },
      {
        path: 'change-schedule-conflict',
        name: `${routeNamePrefix}.change-schedule-conflict`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request" */ './components/change-schedule-conflict/change-list-page.vue'
            )
          ),
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.view`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/view')
          ),
      },
    ],
  },
]
