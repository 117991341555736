<template>
  <div>
    <div v-if="!isEditing">
      <MRow>
        <MCol :size="6">{{ count }}</MCol>
        <MCol v-if="!disabled" :size="6" class="text-right">
          <MButton
            shape="circle"
            :shadow="false"
            variant="transparent"
            @click="handleEdit"
          >
            <MIcon name="pencil" class="text-netural-lightest"></MIcon>
          </MButton>
          <FlotoDeleteBtn
            :message="$t('confirm_remove_item', { item: $tc('down_time') })"
            @confirm="handleRemove"
          >
            <template v-slot:trigger>
              <span>
                <MTooltip>
                  <template v-slot:trigger>
                    <MButton
                      shape="circle"
                      :shadow="false"
                      variant="transparent"
                    >
                      <MIcon
                        name="times-circle"
                        class="text-netural-lightest"
                      ></MIcon>
                    </MButton>
                  </template>
                  {{ $t('delete') }}
                </MTooltip>
              </span>
            </template>
          </FlotoDeleteBtn>
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="6" class="pr-0">
          <FlotoFormItemReadOnly :label="`${$t('start')} ${$t('date')}`">
            {{ schedule.startTime | datetime }}
          </FlotoFormItemReadOnly>
        </MCol>
        <MCol :size="6" class="pr-0">
          <FlotoFormItemReadOnly :label="`${$t('end')} ${$t('date')}`">
            {{ schedule.endTime | datetime }}
          </FlotoFormItemReadOnly>
        </MCol>
      </MRow>
      <FlotoFormItemReadOnly :label="`${$t('description')}`">
        {{ schedule.description || '---' }}
      </FlotoFormItemReadOnly>
    </div>
    <DownTimeForm
      v-else
      v-bind="$attrs"
      :disabled="disabled"
      :resource="resource"
      :count="count"
      :schedule="schedule"
      @submit="handleSubmit"
      @cancel="handleCancel"
    />
    <MDivider dashed />
  </div>
</template>

<script>
import DownTimeForm from './down-time-form'
export default {
  name: 'DownTimeItem',
  components: { DownTimeForm },
  props: {
    disabled: { type: Boolean, default: false },
    schedule: {
      type: Object,
      default() {
        return {}
      },
    },
    resource: { type: Object, required: true },
    count: { type: Number, default: undefined },
  },
  data() {
    return {
      isEditing: false,
    }
  },
  methods: {
    handleEdit() {
      this.isEditing = true
    },
    handleCancel() {
      this.isEditing = false
    },
    handleSubmit(date) {
      this.isEditing = false
      this.$emit('submit', date)
    },
    handleRemove() {
      this.$emit('remove', this.schedule)
    },
  },
}
</script>
