<template>
  <div class="flex flex-col relative">
    <div class="flex mr-2">
      <div v-for="(user, index) in countUsers" :key="user.id">
        <FlotoUserAvatar
          :avatar="user.avatar"
          class="-ml-3 shadow-md rounded-full"
          :style="{ zIndex: user.length + 2 - index }"
          show-tooltip
          :default-text="user.name"
          size="medium"
        />
      </div>

      <FlotoDropdownPicker
        v-if="resolvedUsers.length > 4"
        :options="resolvedUsers"
        :style="{ zIndex: '7' }"
      >
        <template v-slot:trigger="{ toggle }">
          <div
            :closable="false"
            class="flex -ml-3 w-8 h-8 bg-primary rounded-full text-white shadow-md justify-center"
            @click="toggle"
          >
            <MTooltip>
              <template v-slot:trigger>
                <div class="flex text-md self-center cursor-pointer">
                  +{{ resolvedUsers.slice(4).length }}
                </div>
              </template>
              {{ $t('Technicans currently viewing') }}
            </MTooltip>
          </div>
        </template>

        <template v-slot:menu-item="{ item }">
          <div class="flex" style="max-width: 350px;">
            <FlotoAvatarName
              :avatar="item.avatar"
              :name="item.name"
              :use-icon="true"
            />
          </div>
        </template>
      </FlotoDropdownPicker>
    </div>
  </div>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'

export default {
  name: 'CurrentWatchingUsers',
  props: {
    userIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    ...TechnicianComputed,
    resolvedUsers() {
      const userIds = this.userIds.map((i) => +i)
      return this.technicianOptions.filter((i) => userIds.includes(i.id))
    },
    countUsers: function() {
      return this.resolvedUsers.slice(0, 4)
    },
  },
}
</script>
