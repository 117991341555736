<template>
  <div class="flex items-center">
    <div v-if="activeEmoji.description" class="text-netural-lightest font-thin">
      <MTag :closable="false">
        {{ activeEmoji.description }}
      </MTag>
    </div>
    <div class="flex cursor-pointer">
      <template v-for="scale in systemAsCustonField.ratingScales">
        <div
          :key="scale.scaleNumber"
          :class="{
            active:
              ratingType === 'star'
                ? scale.scaleNumber <= value
                : scale.scaleNumber === value,
          }"
          class="sigle-emoji"
          @click="handleChange(scale.scaleNumber)"
        >
          <MTooltip>
            <template v-slot:trigger>
              <img
                v-if="ratingType === 'custom'"
                :src="scale.emojiFileSrc"
                style="height: 22px"
              />
              <span v-else>{{ scale.emoji }}</span>
            </template>
            {{ scale.description }}
          </MTooltip>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { FormComputed } from '@state/modules/form'
export default {
  name: 'RequestRatingView',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Number, default: 0 },
    // eslint-disable-next-line
    disabled: { type: Boolean, default: true },
  },
  computed: {
    ...FormComputed,
    activeEmoji() {
      return (
        this.systemAsCustonField.ratingScales.find(
          (s) => s.scaleNumber === this.value
        ) || {}
      )
    },
    ratingType() {
      return this.systemAsCustonField.ratingType
    },
    systemAsCustonField() {
      return this.feedbackFields().find((f) => f.canDelete === false) || {}
    },
  },
  methods: {
    handleChange(value) {
      if (this.disabled) {
        return
      }
      this.$emit('change', value)
    },
  },
}
</script>
<style lang="less" scoped>
.sigle-emoji {
  @apply mr-2;

  // font-size: 32px;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
}
</style>
