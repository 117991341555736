<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { UserGroupsMethods } from '@state/modules/user-group'
import { TechnicianMethods } from '@state/modules/technician'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'UserGroupsModule',
  page() {
    return {
      title: `${this.$tc('user')} ${this.$tc('group', 2)}`,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (['requester', 'technician'].indexOf(to.params.groupType) === -1) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.myAllowedModules.indexOf('admin.user.users_groups') === -1) {
        return vm.$router.replace({ name: '404' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    if (from.params.groupType === 'requester') {
      this.requesterGroups()
    }
    if (from.params.groupType === 'technician') {
      this.technicianGroups()
      this.fetchTechnicians()
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.users') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  methods: {
    ...UserGroupsMethods,
    ...TechnicianMethods,
  },
}
</script>
