<template>
  <FormRulesProvider :module-name="moduleName">
    <KanbanView
      :module-name="moduleName"
      :search-title="searchTitle"
      :fetch-fn="getTicketsByStatusId"
      :update-fn="handleUpdate"
      :back-link="backLink"
      @viewChange="$emit('viewChange', $event)"
    >
    </KanbanView>
  </FormRulesProvider>
</template>

<script>
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import { getTicketsByStatusIdApi } from '../../ticket-api'
import KanbanView from '@components/kanban-view/kanban-view'
export default {
  name: 'RequestKanbanView',
  components: { KanbanView, FormRulesProvider },
  props: {
    dashboardModuleName: { type: String, default: undefined },
    defaultQualifications: { type: Object, default: null },
    searchTitle: { type: String, default: null },
    backLink: { type: Object, default: undefined },
  },
  data() {
    this.moduleName = this.dashboardModuleName || this.$route.params.ticketType
    return {}
  },
  methods: {
    getTicketsByStatusId(statusId, qualifications, limit, offset, sortcolumn) {
      return getTicketsByStatusIdApi(
        statusId,
        this.moduleName,
        this.defaultQualifications || qualifications,
        limit,
        offset,
        sortcolumn
      )
    },
    handleUpdate(item) {
      return Promise.resolve(item)
    },
  },
}
</script>
