import { Module } from '@plugins/modular'
import Routes from './ticket-routes'
import configs from './config'

class TicketModule extends Module {
  /**
   * @constructor
   * @param {[key: string]: string} config
   */
  constructor(config = configs) {
    /** string name this name is used to get module from module manager */
    super(config.name, config)
    this.translationKey = config.translationKey || name
  }
  routes = Routes

  /**
   * Return the locale loading function
   * @param {string} locale
   * @return {Promise<{}>}
   */
  // getLocaleMessages(locale) {
  //   return import(
  //     /* webpackChunkName: "locale-[request]" */ `./locales/${locale}`
  //   ).then((m) => m.default || m)
  // }
}

export default TicketModule
