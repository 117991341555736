import api from '@api'

export function getSupportedLanguagesApi() {
  return api.get(`/supported/languages`).then((data) => {
    return {
      items: data.map((d) => {
        return {
          key: d.key,
          text: d.value,
        }
      }),
    }
  })
}

export function getEnabledLanguagesApi() {
  return api.get('/enabled/languages')
}
