import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { buildNameFilterQuery } from '@data/qualification'
import {
  transformTemplate,
  transformTemplateForServer,
  transformTemplateForList,
} from '@data/template'
import { isPortalLogin } from '@utils/auth'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getTemplatesApi(
  { moduleName, filter, transform = true },
  limit,
  offset
) {
  return api
    .post(
      `${
        isPortalLogin() ? '/cportal' : ''
      }/${moduleName}/template/search/byqual`,
      filter ? { qualDetails: buildNameFilterQuery(filter) } : {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map((t) =>
          transform ? transformTemplateForList(t) : t
        ),
        total: data.totalCount,
      }
    })
}

export function createTemplateApi(moduleName, template) {
  return api.post(
    `/${moduleName}/template`,
    transformTemplateForServer(moduleName, template),
    {
      message: __rootT('created_successfully', {
        resource: __rootTc('template'),
      }),
    }
  )
}

export function getTemplateApi(moduleName, id) {
  return api
    .get(`/${moduleName}/template/${id}`)
    .then((data) => transformTemplate(data))
}

export function updateTemplateApi(moduleName, data) {
  return api.patch(
    `/${moduleName}/template/${data.id}`,
    transformTemplateForServer(moduleName, data),
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('template'),
      }),
    }
  )
}

export function deleteTemplateApi(moduleName, id) {
  return api.delete(`/${moduleName}/template/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('template'),
    }),
  })
}
