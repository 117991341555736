import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, breadcrumbKey: 'user', nonLinked: true },
    children: [
      {
        path: ':groupType',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "users" */ './views/list')),
        meta: {
          titleFn(route, translator, pluralTranslator) {
            return `${translator(route.params.groupType)} ${pluralTranslator(
              'group'
            )}`
          },
        },
      },
      {
        path: ':groupType/create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "users" */ './views/create')
          ),
        meta: {},
      },
      {
        path: ':groupType/:id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "users" */ './views/edit')),
        meta: {},
      },
    ],
  },
]
