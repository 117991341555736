<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
export default {
  name: 'TemplatesModule',
  page() {
    return {
      title: this.$tc('template'),
    }
  },
  beforeRouteEnter(to, from, next) {
    const allowedForms = [
      Constants.REQUEST,
      Constants.PROBLEM,
      Constants.CHANGE,
      Constants.RELEASE,
    ]
    if (
      to.params.moduleName &&
      allowedForms.indexOf(to.params.moduleName) === -1
    ) {
      return next({ name: '404' })
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
}
</script>
