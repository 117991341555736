<template>
  <a @click="triggerAction">
    <MIcon name="comment-smile" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $t('ask_for_feedback') }}
    </span>
    <FlotoConfirmModal
      :open="showModal"
      @hide="showModal = false"
      @confirm="sendFeedback"
    >
      <template v-slot:icon>
        <MIcon
          name="comment-smile"
          class="w-full text-secondary-orange"
          size="2x"
        />
      </template>
      <template v-slot:message>
        {{ $t('confirm_aks_feedback') }}
      </template>
    </FlotoConfirmModal>
  </a>
</template>

<script>
import { sendFeedbackApi } from '../../ticket-api'
export default {
  name: 'SendFeedback',
  props: { resourceId: { type: Number, required: true } },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    triggerAction() {
      this.$emit('trigger')
      setTimeout(() => {
        this.showModal = true
      }, 400)
    },
    sendFeedback() {
      sendFeedbackApi(this.resourceId).then(() => {
        this.showModal = false
        this.$emit('done')
      })
    },
  },
}
</script>
