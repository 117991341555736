<template>
  <FlotoConfirmModal open @hide="$emit('hide')" @confirm="$emit('confirm')">
    <template v-slot:icon>
      <MIcon
        name="check-circle"
        class="w-full text-secondary-green"
        size="2x"
      />
    </template>
    <template v-slot:message>
      <slot name="message">
        {{
          $t('confirm_submit', {
            resource: $tc(moduleName),
          })
        }}
      </slot>
    </template>
  </FlotoConfirmModal>
</template>

<script>
export default {
  name: 'ConfirmSubmitModal',
  props: {
    moduleName: { type: String, required: true },
  },
}
</script>
