<template>
  <MRow :gutter="0">
    <MCol :lg="12" :sm="12" auto-size>
      <MRow class="flex">
        <MCol v-if="conversionType === 'inc_to_sr'" :size="6">
          <FlotoFormItem
            id="service_request"
            :label="$t('service_request')"
            rules="required|nonzero"
          >
            <ServiceCatalogPicker
              v-model="formValue.serviceCatalogId"
              as-input
              status="published"
              :allow-clear="false"
            />
          </FlotoFormItem>
        </MCol>
        <template
          v-if="
            conversionType === 'inc_to_sr' ? formValue.serviceCatalogId : true
          "
        >
          <MCol :size="12">
            <FlotoFormItem
              id="overwrite_existing_data"
              :validation-label="$t('overwrite_existing_data')"
            >
              <MCheckbox v-model="formValue.overwriteExistingData">
                {{ $tc('overwrite_existing_data') }}
              </MCheckbox>
            </FlotoFormItem>
          </MCol>
          <MCol v-if="conversionType === 'inc_to_sr'" :size="12">
            <FlotoFormItem
              id="remove_previous_tasks"
              :validation-label="$t('remove_previous_tasks')"
            >
              <MCheckbox v-model="formValue.removePreviousTasks">
                {{ $tc('remove_previous_tasks') }}
              </MCheckbox>
            </FlotoFormItem>
          </MCol>
          <MCol :size="12">
            <FlotoFormItem
              id="remove_relations"
              :validation-label="$t('remove_relations')"
            >
              <MCheckbox v-model="formValue.removeRelations">
                {{ $tc('remove_relations') }}
              </MCheckbox>
            </FlotoFormItem>
          </MCol>
          <MCol :size="12">
            <FlotoFormItem
              id="remove_approval_details"
              :validation-label="$t('remove_approval_details')"
            >
              <MCheckbox v-model="formValue.removeApprovalDetails">
                {{ $tc('remove_approval_details') }}
              </MCheckbox>
            </FlotoFormItem>
          </MCol>
          <MCol v-if="conversionType === 'sr_to_inc'" :size="12">
            <b> {{ $tc('note') }}: </b>
            {{ $tc('remove_task_note_for_sr_to_inc') }}
          </MCol>
        </template>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import ServiceCatalogPicker from '@components/data-picker/service-catalog-picker'
export default {
  name: 'ConversionConfig',
  components: { ServiceCatalogPicker },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    conversionType: { type: String, required: true },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('change', v)
      },
    },
  },
}
</script>
