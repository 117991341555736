<template>
  <a @click="trigger">
    <MIcon name="merge" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $tc('convert_to') }}
      {{
        conversionType === 'inc_to_sr'
          ? $tc('service_request')
          : $tc('incident')
      }}
    </span>
    <FlotoDrawerForm
      ref="drawer"
      :open="isDrawerOpen"
      width="60%"
      @cancel="cancelAction"
      @submit="handleSubmit"
    >
      <template v-slot:header>
        {{ $t('convert_to') }}
        {{
          conversionType === 'inc_to_sr'
            ? $tc('service_request')
            : $tc('incident')
        }}
      </template>
      <ConversionConfig v-model="formData" :conversion-type="conversionType" />
      <template
        v-if="formData.overwriteExistingData || conversionType === 'inc_to_sr'"
      >
        <ConversionOverwriteForm
          v-model="formData.requestRest"
          :conversion-type="conversionType"
          :service-catalog-id="formData.serviceCatalogId"
          :overwrite-existing-data="formData.overwriteExistingData"
        />
      </template>
      <template v-slot:actions="{ submit, hide }">
        <MButton
          id="save-btn"
          class="mx-1"
          :loading="processing"
          @click="submit"
        >
          {{ $t('convert') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
    <ConfirmSubmitModal
      v-if="showConfirmModal"
      :module-name="$constants.REQUEST"
      @confirm="update"
      @hide="showConfirmModal = false"
    >
      <template v-slot:icon>
        <MIcon name="exclamation-triangle" />
      </template>
      <template v-slot:message>
        {{
          conversionType === 'inc_to_sr'
            ? $t('converting_warning_incident_to_service_request')
            : $t('converting_warning_service_request_to_incident')
        }}
      </template>
    </ConfirmSubmitModal>
  </a>
</template>
<script>
import { FormComputed } from '@state/modules/form'
import CloneDeep from 'lodash/cloneDeep'
import Pick from 'lodash/pick'
import { flattenFields, getFieldIds } from '@data/form'
import ConfirmSubmitModal from '@modules/user-survey-response/views/confirm-submit-modal'
import ConversionConfig from './conversion-config'
import ConversionOverwriteForm from './conversion-overwrite-form'
import { conversionApi } from '../../../ticket-api.js'
export default {
  name: 'ConversionDrawer',
  components: { ConversionConfig, ConversionOverwriteForm, ConfirmSubmitModal },
  props: {
    resource: { type: Object, required: true },
  },
  data() {
    return {
      isDrawerOpen: false,
      formData: {},
      showConfirmModal: false,
      processing: false,
    }
  },
  computed: {
    ...FormComputed,
    incidentFlattenFields() {
      return flattenFields(
        ((this.requestForm || {}).fields || []).filter(
          (s) => s.inputType !== 'status'
        )
      )
    },
    defaultData() {
      const resourceData = Pick(
        {
          ...this.resource,
          requester:
            (this.resource.requesterData || {}).name || this.resource.requester,
        },
        [
          ...(getFieldIds(this.incidentFlattenFields) || []),
          'id',
          'name',
          'requester',
          'requesterId',
          'serviceCatalogId',
        ]
      )
      return {
        requestRest: {
          ...resourceData,
        },
      }
    },
    conversionType() {
      if (this.resource.serviceCatalogId) {
        return 'sr_to_inc'
      }
      return 'inc_to_sr'
    },
  },
  watch: {
    'formData.overwriteExistingData': {
      immediate: true,
      handler(newValue, preValue) {
        if (newValue === true) {
          this.formData.requestRest = this.defaultData.requestRest
        }
        if (newValue === false) {
          this.formData.requestRest = {}
        }
      },
    },
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 400)
      this.$emit('trigger')
      this.formData = { ...CloneDeep(this.defaultData), requestRest: {} }
    },
    cancelAction() {
      this.isDrawerOpen = false
    },
    handleSubmit() {
      this.showConfirmModal = true
    },
    update() {
      this.processing = true
      const source =
        this.conversionType === 'inc_to_sr' ? 'incident' : 'service_request'
      const target =
        this.conversionType === 'inc_to_sr' ? 'service_request' : 'incident'
      return conversionApi(
        this.$constants.REQUEST,
        this.resource.id,
        {
          ...this.formData,
          conversionType: this.conversionType,
        },
        source,
        target
      )
        .then((data) => {
          this.isDrawerOpen = false
          this.$emit('refresh')
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
