import api from '@api'
import { transformWidget, transformWidgetTable } from '@data/dashboard'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'
import {
  transformUserSurvey,
  transformScheduleSurvey,
  transformUserSurveyForServer,
  transformScheduleSurveyForList,
  transformScheduleSurveyForServer,
  transformSurveyDashlet,
  transformScheduleSurveyAdditionalComments,
} from '@data/user-survey'

import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'

import moduleConfig from './config'

const __rootT = getRootTranslator()
const __moduleT = getModuleTranslator(moduleConfig.translationKey)

const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.displayName || qualificationFactors.selectedName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName || qualificationFactors.selectedName,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.exludedIds) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        qualificationFactors.exludedIds,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function searchUserSurveysApi(
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/user_survey/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserSurvey),
        total: data.totalCount,
      }
    })
}

export function getScheduleSurveysApi(limit, offset) {
  return api
    .post(
      '/user_survey_schedule/search/byqual',
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformScheduleSurveyForList),
        total: data.totalCount,
      }
    })
}

export function deleteScheduleSurveyApi(id) {
  return api.delete(`/user_survey_schedule/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('schedule_survey'),
    }),
  })
}

export function toggleEnableApi(id, data) {
  return api
    .patch(`/user_survey_schedule/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('schedule_survey'),
      }),
    })
    .then((data) => transformScheduleSurveyForList(data))
}

export function createScheduleSurveyApi(survey) {
  return api.post(
    '/user_survey_schedule',
    transformScheduleSurveyForServer(survey),
    {
      message: __rootT('created_successfully', {
        resource: __rootTc('schedule_survey'),
      }),
    }
  )
}

export function updateScheduleSurveyApi(data, id) {
  return api.patch(
    `/user_survey_schedule/${id}`,
    transformScheduleSurveyForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('schedule_survey'),
      }),
    }
  )
}

export function getScheduleSurveyApi(id) {
  return api
    .get(`/user_survey_schedule/${id}`)
    .then((data) => transformScheduleSurvey(data))
}

export function getUserSurveysApi(filter, limit, offset) {
  return api
    .post(
      `/user_survey/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.selectedName ||
          filter.ids ||
          filter.exludedIds
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserSurvey),
        total: data.totalCount,
      }
    })
}

export function getApi(id) {
  return api.get(`/user_survey/${id}`).then(transformUserSurvey)
}

export function createApi(data) {
  return api
    .post(`/user_survey`, transformUserSurveyForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('user_survey'),
      }),
    })
    .then(transformUserSurvey)
}

export function copySurveyApi(data) {
  return api
    .post(`/user_survey/${data.id}/clone`, transformUserSurveyForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('user_survey'),
      }),
    })
    .then(transformUserSurvey)
}

export function updateApi(data, id, type) {
  return api
    .patch(
      `/user_survey/${data.id ? data.id : id}`,
      transformUserSurveyForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc(type || 'user_survey'),
        }),
      }
    )
    .then(transformUserSurvey)
}

export function updateLuanchApi(data) {
  return api
    .post(`/user_survey/launch`, transformUserSurveyForServer(data), {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('launch')} ${__rootTc('survey')}`,
      }),
    })
    .then(transformUserSurvey)
}

export function deleteApi(id) {
  return api.delete(`/user_survey/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc('user_survey'),
    }),
  })
}

export function validateAudienceApi(data, additionsParams = {}) {
  return api.post(
    `/validate/audience`,
    {
      requesterGroup: data.requesterGroup || [],
      excludeTechnicians: data.excludeTechnicians || false,
    },
    {
      params: {
        ...additionsParams,
      },
      notification: {
        error: {
          message: 'Invalid',
          description: 'Invalid Audience',
        },
      },
    }
  )
}

export function getUserSurveyResponseApi(surveyId) {
  return api.get(`user_survey_response/${surveyId}`).then((data) => {
    return (data.dashletsSet || []).map(transformSurveyDashlet)
  })
}

export function getSurveyQuestionPreviewApi(id, filters = {}) {
  return api
    .post(
      `analytics/user_survey_response/widget/preview/${id}`,
      {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      {
        notify: false,
      }
    )
    .then((data) => {
      return {
        widget: transformWidget({
          ...data.widget,
          // name: data.widget.description,
          qual: data.table.qual,
        }),
        data: transformWidgetTable(data.table),
        questionInfo: {
          notRespondedRequests: data.notRespondedRequests,
          respondedRequests: data.respondedRequests,
          totalRequests: data.totalRequests,
          waitingForResponse: data.waitingForResponse,
          type: data.widgetType,
        },
      }
    })
}

export function updateQuestionWidgetApi(id, type) {
  return api
    .patch(`analytics/widget/${id}`, { visualizationType: type })
    .then(transformWidget)
}

export function getSurveyRespondePreviewOnlyApi(query) {
  return api
    .post(
      `analytics/public/user_survey_response/widget/preview/${query.widgetId}?userId=${query.userId}&token=${query.token}`,
      query,
      {
        timeout: 0,
        message: '',
        notification: {
          error: {
            message: __rootT('error'),
            description: __moduleT('survey_preview_failed'),
          },
        },
      }
    )
    .then((data) => {
      return {
        widget: transformWidget({
          ...data.widget,
          // name: data.widget.description,
          qual: data.table.qual,
        }),
        data: transformWidgetTable(data.table),
      }
    })
}

export function getAdditionalCommentsApi(id, pageSize, offset) {
  return api
    .get(`survey_response/${id}`, {
      params: {
        offset: offset || 0,
        size: pageSize || 1000,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(
          transformScheduleSurveyAdditionalComments
        ),
      }
    })
}
