<template>
  <a @click="triggerAction">
    <MIcon name="ban" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $t('mark_as_spam') }}
    </span>
    <FlotoConfirmModal
      :open="showModal"
      prevent-auto-close-on-confirm
      :processing="processing"
      @hide="showModal = false"
      @confirm="handleConfirmAction"
    >
      <template v-slot:icon>
        <SpamIcon class="w-full" />
      </template>
      <template v-slot:message>
        {{
          $t('confirm_mark_as_spam', {
            resource: $tc(moduleName),
          })
        }}
      </template>
    </FlotoConfirmModal>
  </a>
</template>

<script>
import Bus from '@utils/emitter'
import SpamIcon from '@assets/icons/custom/modal/spam.svg'

export default {
  name: 'MarkAsSpam',
  components: { SpamIcon },
  props: {
    moduleName: { type: String, required: true },
    bindShortcut: { type: Boolean, default: false },
    handleSpamRequests: { type: Function, default: undefined },
  },
  data() {
    return {
      showModal: false,
      processing: false,
    }
  },
  created() {
    if (this.bindShortcut) {
      const spamHandler = () => {
        this.triggerAction()
      }
      Bus.$on('mark-spam', spamHandler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('mark-spam', spamHandler)
      })
    }
  },
  methods: {
    handleConfirmAction() {
      this.$emit('done')
      if (this.handleSpamRequests) {
        this.processing = true
        this.handleSpamRequests()
          .then(() => {
            this.showModal = false
          })
          .finally(() => (this.processing = false))
      } else {
        this.showModal = false
      }
    },
    triggerAction() {
      this.$emit('trigger')
      // show modal after dropdown is hide
      setTimeout(() => {
        this.showModal = true
      }, 400)
    },
  },
}
</script>
