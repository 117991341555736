<template>
  <div class="flex flex-1 flex-col">
    <template v-if="serviceCatalogFormFields.length">
      <h6 class="mt-2">
        {{ $tc('service_request') }}
        {{ $tc('field', 2) }}
      </h6>
      <MDivider class="mt-0" />
      <FormConsumer
        ref="formRef"
        v-model="formValue"
        :with-submit="false"
        avoid-default-value
        :form-fields="serviceCatalogFormFields"
        :external-data="{
          requestType: 'service_request',
          serviceCatalogId: serviceCatalogId,
        }"
        :module-name="moduleName"
        :apply-form-rules="false"
        :allow-field-mapping="false"
        :service-catalog-id="serviceCatalogId"
      >
        <template v-slot:submit>
          <span />
        </template>
        <template v-slot:reset>
          <span />
        </template>
      </FormConsumer>
    </template>
    <template v-if="incidentFormFields.length && overwriteExistingData">
      <h6 class="mt-2">
        {{ $tc('incident') }}
        {{ $tc('field', 2) }}
      </h6>
      <MDivider class="mt-0" />
      <FormConsumer
        ref="formRef"
        v-model="formValue"
        :with-submit="false"
        avoid-default-value
        :form-fields="incidentFormFields"
        :module-name="moduleName"
        :apply-form-rules="false"
        :allow-field-mapping="false"
      >
        <template v-slot:submit>
          <span />
        </template>
        <template v-slot:reset>
          <span />
        </template>
      </FormConsumer>
    </template>
  </div>
</template>
<script>
import { FormComputed } from '@state/modules/form'
import { PreferenceComputed } from '@state/modules/preference'
import FormConsumer from '@components/form-consumer'
import { getFormApi } from '@modules/form/form-api'
export default {
  name: 'ConversionOverwriteForm',
  components: { FormConsumer },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    conversionType: { type: String, required: true },
    serviceCatalogId: { type: Number, default: undefined },
    overwriteExistingData: { type: Boolean, default: false },
  },
  data() {
    return {
      incidentFormFields: [],
      serviceCatalogFormFields: [],
    }
  },
  computed: {
    ...FormComputed,
    ...PreferenceComputed,
    formValue: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('change', v)
      },
    },
    moduleName() {
      if (this.conversionType === 'inc_to_sr') {
        return this.$constants.SERVICE_CATALOG
      }
      return this.$constants.REQUEST
    },
  },
  watch: {
    serviceCatalogId: {
      immediate: true,
      handler: 'getForm',
    },
  },
  created() {
    this.getFormFields()
  },
  methods: {
    getFormFields() {
      if (this.conversionType === 'inc_to_sr') {
        // if (this.serviceCatalogId) {
        //   this.getForm()
        // }
        this.incidentFormFields = (
          (this.requestForm || {}).fields || []
        ).filter(
          (s) =>
            [
              'status',
              'template',
              'transition_model',
              'requester',
              'requesterId',
            ].indexOf(s.inputType) === -1
        )
      } else {
        this.incidentFormFields = (
          (this.requestForm || {}).fields || []
        ).filter(
          (s) =>
            ['status', 'requester', 'requesterId', 'transition_model'].indexOf(
              s.inputType
            ) === -1
        )
      }
    },
    getForm() {
      if (this.serviceCatalogId) {
        getFormApi(
          this.$constants.SERVICE_CATALOG,
          this.serviceCatalogId,
          undefined,
          false
        ).then((data) => {
          this.serviceCatalogFormFields = data.fields
        })
      }
    },
  },
}
</script>
