import api from '@api'
import GroupBy from 'lodash/groupBy'
import UniqBy from 'lodash/uniqBy'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const transformSmartSuggestionsForServer = (suggestion) => {
  return {
    model: suggestion.moduleName,
    fieldSuggestion: suggestion.fieldSuggestion,
    fields: suggestion.fields,
    minAccuracyLevel: suggestion.minAccuracyLevel,
    notificationText:
      (suggestion.fields || []).indexOf('knowledge_base') >= 0
        ? suggestion.notificationText
        : '',
  }
}

const transformSmartSuggestions = (suggestion) => {
  return {
    id: suggestion.id,
    moduleName: suggestion.model,
    fieldSuggestion: suggestion.fieldSuggestion,
    fields: suggestion.fields || [],
    minAccuracyLevel: suggestion.minAccuracyLevel,
    notificationText: suggestion.notificationText,
  }
}

export function smartSuggestionsApi(moduleName) {
  return api.get(`${moduleName}/smartsuggestion`).then((data) => {
    return transformSmartSuggestions(data || {})
  })
}

export function updateSmartSuggestionsApi(moduleName, data) {
  return api
    .patch(
      `${moduleName}/smartsuggestion/${data.id}`,
      transformSmartSuggestionsForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootT('smart_suggestions'),
        }),
      }
    )
    .then(transformSmartSuggestions)
}

export function smartSuggestionsSupportedDataApi(moduleName) {
  return api
    .get(`/${moduleName}/smartsuggestiondata/supporteddata`)
    .then((data) => {
      const allData = (data.smartFieldDetails || []).map((d) => ({
        group: d.groupName,
        name: d.displayName,
        paramName: d.enumName,
      }))
      const finalData = {}
      const groupdData = GroupBy(allData, 'group')
      const groupMap = UniqBy(
        allData.map((d) => ({
          groupName: d.group,
        })),
        'groupName'
      )
      groupMap.forEach((g) => {
        finalData[g.groupName] = groupdData[g.groupName]
      })
      return finalData
    })
}
export function smartSuggestionsAccuracyApi(moduleName) {
  return api.get(`${moduleName}/smartsuggestiondata`).then((data) => {
    const groupdData = GroupBy(data.objectList || [], 'smartField')
    return groupdData
  })
}
// export function smartSuggestionsTrainModelApi(moduleName) {
//   return api.get(`${moduleName}/smartsuggestion/trainmodel`)
// }

export function smartSuggestionsTrainModelApi(moduleName, data) {
  return api.post(`/${moduleName}/smartsuggestion/trainmodel`, data, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('train_model'),
    }),
  })
}

export function getSuggestedFieldValueApi(moduleName, ids) {
  return api
    .post(
      `${moduleName}/suggestions`,
      {
        objectIds: ids,
      },
      {
        notify: false,
      }
    )
    .then((data) => data.objectList)
}
