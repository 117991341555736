<template>
  <FormHelperProvider>
    <TicketFormEdit v-bind="$attrs" v-on="$listeners" />
  </FormHelperProvider>
</template>

<script>
import TicketFormEdit from './ticket-form-edit'
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'
export default {
  name: 'TicketFormEditModal',
  components: { TicketFormEdit, FormHelperProvider },
}
</script>
