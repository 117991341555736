import api from '@api'
import { transformSupportData } from '@data/workflow'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  transformSlaForServer,
  transformSla,
  transformSlaForList,
  transformSLASettings,
  transformSLASettingsForServer,
} from '@data/sla'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  if (qualificationFactors.filterType) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        qualificationFactors.filterType !== 'enabled',
        'boolean',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getSupportDataApi(model, id) {
  return api
    .get(`${model}${id ? `/${id}` : ''}/slapolicy/supportedData`)
    .then(transformSupportData)
}

export function createSlaApi(sla) {
  return api.post(
    `${sla.moduleName}${sla.parentId ? `/${sla.parentId}` : ''}/slapolicy`,
    transformSlaForServer(sla),
    {
      message: __rootT('created_successfully', {
        resource: __rootT('sla'),
      }),
    }
  )
}

export function getSlaApi(moduleName, id, params = {}) {
  return api
    .get(`${moduleName}/slapolicy/${id}`, params)
    .then((data) => transformSla(data))
}

export function getSlasApi(qualificationFactors, limit, offset) {
  return api
    .post(
      `${qualificationFactors.moduleName}${
        qualificationFactors.parentId ? `/${qualificationFactors.parentId}` : ''
      }/slapolicy/search/byqual`,
      { qualDetails: buildQualifications(qualificationFactors) },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformSlaForList),
        total: data.totalCount,
      }
    })
}

export function updateSlaApi(moduleName, data) {
  return api.patch(
    `${moduleName}/slapolicy/${data.id}`,
    transformSlaForServer(data),
    {
      message: __rootT('saved_successfully', {
        resource: __rootT('sla'),
      }),
    }
  )
}

export function bulkUpdateSlaApi(moduleName, items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`${moduleName}/slapolicy/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: __rootT('sla'),
    }),
  })
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/slapolicy/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootT('sla'),
      }),
    })
    .then((data) => transformSlaForList(data))
}

export function deleteSlaApi(moduleName, id) {
  return api.delete(`${moduleName}/slapolicy/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootT('sla'),
    }),
  })
}

export function getSLASettingApi() {
  return api.get('/slasetting').then(transformSLASettings)
}

export function updateSLASettingApi(data) {
  return api
    .patch('/slasetting', transformSLASettingsForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('sla_setting'),
      }),
    })
    .then(transformSLASettings)
}
