<template>
  <FlotoContentLoader :loading="requestLoading" :height="600">
    <component :is="useFormHelpterProvider ? 'FormHelperProvider' : 'div'">
      <FormConsumer
        ref="formRef"
        :form-fields="allCustomFormFields"
        :additional-custom-form-fields="additionalCustomFormFields"
        :module-name="moduleName"
        :value="value"
        :with-submit="withSubmit"
        validate-archived-value
        allow-field-mapping
        :focus-event-brodcast="focusEventBrodcast"
        :avoid-default-value="avoidDefaultValue"
        :apply-form-rules="formRulesSupportedModules.indexOf(moduleName) >= 0"
        :form-rules="formRules"
        @templateSelected="$emit('templateSelected', $event)"
        @subject-blur="$emit('subject-blur', $event)"
        @description-blur="$emit('description-blur', $event)"
        @startDate-update="$emit('startDate-update', $event)"
        @endDate-update="$emit('endDate-update', $event)"
        @change="$emit('change', $event)"
        @reset-form="$emit('reset-form', $event)"
        @requester-details="$emit('requester-details', $event)"
        @submit="handleFormSubmitted"
      >
        <template v-slot:submit>
          <span />
        </template>
        <template v-slot:reset>
          <span />
        </template>
        <template
          v-if="
            (hasAssetModule && useLinkAsset) || (hasCmdbModule && useLinkCi)
          "
          v-slot:additional-fields
        >
          <MCol
            v-if="hasAssetModule && useLinkAsset"
            :size="12"
            class="mb-3 ml-2"
            :class="{ 'mt-2': !withSubmit }"
          >
            <LinkAssetDrawer
              :module-name="$constants.ASSET"
              :default-selected-assets="selectedAssets"
              @change="handleLinkAssetSelectionChange"
            />
          </MCol>
          <MCol
            v-if="hasCmdbModule && useLinkCi"
            :size="12"
            class="mb-3 ml-2"
            :class="{ 'mt-2': !withSubmit }"
          >
            <LinkAssetDrawer
              :module-name="$constants.CMDB"
              :default-selected-assets="selectedCi"
              @change="handleLinkCiSelectionChange"
            />
          </MCol>
        </template>
      </FormConsumer>
    </component>
  </FlotoContentLoader>
</template>

<script>
import SortBy from 'lodash/sortBy'
import IsEqual from 'lodash/isEqual'
import FindIndex from 'lodash/findIndex'
import { LicenseComputed } from '@state/modules/license'
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'
import { PreferenceComputed } from '@state/modules/preference'
import LinkAssetDrawer from '@modules/ticket/components/link-asset-drawer'
import { getFormRulesApi } from '@modules/form-rules/form-rules-api'
import { formRulesSupportedModules } from '@data/form-rules'

export default {
  name: 'TicketForm',
  components: { FormConsumer, LinkAssetDrawer, FormHelperProvider },
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    useTemplate: { type: Boolean, default: true },
    // eslint-disable-next-line
    useRequester: { type: Boolean, default: true },
    // eslint-disable-next-line
    useCcEmail: { type: Boolean, default: true },
    // eslint-disable-next-line
    useLinkAsset: { type: Boolean, default: true },
    // eslint-disable-next-line
    useLinkCi: { type: Boolean, default: true },
    // eslint-disable-next-line
    considerPriorityMatrix: { type: Boolean, default: true },
    // eslint-disable-next-line
    useTransitionModel: { type: Boolean, default: true },
    // eslint-disable-next-line
    useFormHelpterProvider: { type: Boolean, default: true },
    moduleName: { type: String, required: true },
    withSubmit: { type: Boolean, required: true },
    avoidDefaultValue: { type: Boolean, default: false },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    exclude: {
      type: Array,
      default() {
        return []
      },
    },
    defaultSelectedAssets: {
      type: Array,
      default() {
        return []
      },
    },
    defaultSelectedCi: {
      type: Array,
      default() {
        return []
      },
    },
    additionalCustomFormFields: {
      type: Array,
      default() {
        return []
      },
    },
    focusEventBrodcast: { type: Boolean, default: false },
    stages: { type: Array, default: undefined },
  },
  data() {
    this.formRulesSupportedModules = formRulesSupportedModules
    return {
      selectedAssets: [],
      selectedCi: [],
      formRules: [],
      formRulesLoading: true,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...FormComputed,
    globalLoading() {
      return this.requestLoading || this.formRulesLoading
    },
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
    hasCmdbModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.CMDB) >= 0
    },
    customFormFields() {
      if (this.moduleName === this.$constants.REQUEST) {
        const enablePriorityMatrix =
          this.tenantPrefrences.EnablePriorityMatrix &&
          this.tenantPrefrences.EnablePriorityMatrix.value
        let allFields = this.requestFields({
          exclude:
            enablePriorityMatrix && this.considerPriorityMatrix
              ? [
                  'priority',
                  ...(this.useTemplate ? [] : ['template']),
                  ...(this.useCcEmail ? [] : ['ccemail']),
                  ...(this.useRequester ? [] : ['requester', 'api']),
                  ...(this.useTransitionModel ? [] : ['transition_model']),
                ]
              : [
                  ...(this.useTemplate ? [] : ['template']),
                  ...(this.useCcEmail ? [] : ['ccemail']),
                  ...(this.useRequester ? [] : ['requester', 'api']),
                  ...(this.useTransitionModel ? [] : ['transition_model']),
                ],
        })
        if (!this.useCcEmail) {
          const i = FindIndex(allFields || [], { paramName: 'requester' })
          if (i !== -1) {
            allFields = [
              ...allFields.slice(0, i),
              {
                ...allFields[i],
                attributes: {
                  ...(allFields[i].attributes || {}),
                  disabled: true,
                },
              },
              ...allFields.slice(i + 1),
            ]
          }
        }
        return allFields
      } else if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        return this[`${this.moduleName}Fields`]({
          exclude: [
            ...(this.useTemplate ? [] : ['template']),
            ...(this.useRequester ? [] : ['requesterid']),
            ...(this.useTransitionModel ? [] : ['transition_model']),
          ],
          stages: ['all'],
        })
      } else {
        return this[`${this.moduleName}Fields`]({
          exclude: [
            ...(this.useTemplate ? [] : ['template']),
            ...(this.useTransitionModel ? [] : ['transition_model']),
          ],
        })
      }
    },
    allCustomFormFields() {
      if (this.additionalCustomFormFields.length) {
        return SortBy(
          [...this.customFormFields, ...this.additionalCustomFormFields],
          'order'
        )
      }
      return this.customFormFields
    },
  },
  watch: {
    defaultSelectedAssets: {
      immediate: true,
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.selectedAssets = [...(newValue || [])]
        }
      },
    },
    defaultSelectedCi: {
      immediate: true,
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.selectedCi = [...(newValue || [])]
        }
      },
    },
  },
  created() {
    this.getFormRules()
  },
  methods: {
    getFormRules() {
      if (this.moduleName !== this.$constants.REQUEST) {
        this.formRulesLoading = false
        return
      }
      return getFormRulesApi({
        moduleName: this.moduleName,
        name: '',
      }).then((data) => {
        this.formRules = data.items.filter((i) => i.enabled)
        this.formRulesLoading = false
      })
    },
    handleFormSubmitted(data) {
      this.$emit('submit', {
        ...data,
        linkAssetIds: this.selectedAssets.map((a) => `${a.id}:${a.model}`),
        linkCiIds: this.selectedCi.map((a) => `${a.id}:${a.model}`),
      })
    },
    submit() {
      this.$refs.formRef.submit()
    },
    handleLinkAssetSelectionChange(items) {
      this.selectedAssets = items
      if (!this.withSubmit) {
        this.$emit('change', {
          ...this.value,
          linkAssetIds: items.map((a) => `${a.id}:${a.model}`),
        })
      }
    },
    handleLinkCiSelectionChange(items) {
      this.selectedCi = items
      if (!this.withSubmit) {
        this.$emit('change', {
          ...this.value,
          linkCiIds: items.map((a) => `${a.id}:${a.model}`),
        })
      }
    },
  },
}
</script>
