<template>
  <FlotoContentLoader
    classes="h-100"
    :transition="useTransition ? undefined : 'none'"
    :loading="loading"
  >
    <SuggestedFieldValuesProvider
      :resource-list="[ticket]"
      :module-name="moduleName"
    >
      <DetailView
        v-if="ticket.id"
        ref="ticketDetailRef"
        :default-sidebar-visible="isPage || defaultSidebarVisible"
        :disabled="isTicketClosed || isChangeDisabled"
        :is-ticket-closed="isTicketClosed"
        :is-ticket-resolve="isTicketResolve"
        :resource="ticket"
        :is-approval="isApproval"
        :approval="approval"
        :additional-info-col-size="isPage ? 9 : 12"
        :module-name="moduleName"
        :update-api="update"
        :change-stage="changeStage"
        :is-allowed-to-edit-due-by="allowToEditDueBy"
        v-bind="{ ...$attrs, ...fieldLabels }"
        @identifiedAsProblem="identifiedAsProblem"
        @purchaseRequest="purchaseRequest"
      >
        <template v-if="isPage" v-slot:before-title>
          <FlotoBackButton
            :to="backLink || $currentModule.getRoute()"
            class="text-neutral-light mx-2"
          />
        </template>
        <template v-slot:actions>
          <CurrentWatchingUsers :user-ids="parellelUsers" />
          <SuggestedFieldsBtn
            v-if="moduleName === $constants.REQUEST"
            :resource="ticket"
            :module-name="moduleName"
            @refresh="refreshTicket"
          />
          <slot
            name="actions"
            :is-ticket-closed="isTicketClosed"
            :handle-close-ticket="handleCloseTicket"
            :handle-reopen-ticket="handleReopenTicket"
          >
            <slot name="action-before" />
            <RequestRatingView
              v-if="
                !isPortalLogin &&
                moduleName === $constants.REQUEST &&
                ticket.rating > 0
              "
              class="mx-1"
              :value="ticket.rating"
            />
            <MTooltip v-if="!isPage">
              <template v-slot:trigger>
                <FlotoLink
                  id="view-btn"
                  as-button
                  variant="neutral-lightest"
                  :shadow="false"
                  shape="circle"
                  :to="
                    $modules.getModuleRoute('ticket', 'view', {
                      params: { id: ticket.id, ticketType: moduleName },
                    })
                  "
                >
                  <MIcon name="eye" />
                </FlotoLink>
              </template>
              {{ $t('view') }} {{ $tc(moduleName) }}
            </MTooltip>
            <MButton
              v-if="isTicketClosed"
              id="reopen-btn"
              class="mx-1"
              @click="handleReopenTicket"
            >
              {{ $t('reopen') }} {{ $tc(moduleName) }}
            </MButton>
            <MButton
              v-else-if="!isClosedStatusHiddenFromFormRule"
              id="close-btn"
              class="mx-1"
              @click="handleCloseTicket"
            >
              {{ $t('close') }} {{ $tc(moduleName) }}
            </MButton>
            <ActionMenu
              :ticket="ticket"
              :change-stage="changeStage"
              :module-name="moduleName"
              :disabled="isTicketClosed"
              :update-watcher="update"
              :is-ticket-closed="isTicketClosed"
              :is-ticket-resolve="isTicketResolve"
              @archive="handleArchiveTicket"
              @update="update"
              @refresh="refreshTicket"
              @approval-changed="refreshTicket(['approval'])"
            />
            <slot name="action-after" />
          </slot>
        </template>

        <!-- system fields as details -->
        <template
          v-if="
            moduleName === $constants.CHANGE ||
            moduleName === $constants.RELEASE
          "
          v-slot:additional-fields
        >
          <ChangeFormConsumer
            :disabled="isTicketClosed"
            :is-change-disabled="isChangeDisabled"
            :module-name="moduleName"
            :resource="ticket"
            :show-no-data="false"
            :update-api="update"
            @edit-additional-info="handleShowEditFormModal"
          />
        </template>

        <!-- default content as tabs -->
        <slot :ticket="ticket">
          <template
            v-if="
              moduleName === $constants.CHANGE ||
              moduleName === $constants.RELEASE
            "
          >
            <MDivider />
            <ChangeStagesContainer
              :module-name="moduleName"
              :resource="ticket"
              :update-api="update"
              :single-field-rules-state-fn="singleFieldRulesStateFn"
              @refresh="refreshTicket"
            />
            <MDivider />
          </template>
          <div :key="tabsRerenderCount" class="tabs mt-6">
            <MAffix>
              <MTab v-model="activeTabKey" size="small" class="affixed-tab">
                <MTabPane
                  v-for="tab in allowedTabs"
                  :key="tab.id"
                  :tab="tab.name"
                />
              </MTab>
            </MAffix>
            <div class="my-4">
              <component
                :is="tabComponent"
                :disabled="
                  tabComponentProps.alwaysEditable
                    ? tabComponentProps.disabledByFormRules || false
                    : tabComponentProps.disabled ||
                      tabComponentProps.disabledByFormRules
                "
                :resource-id="ticket.id"
                :watcher="ticket.watcher"
                :group-id="ticket.groupId"
                :module-name="moduleName"
                :resource="ticket"
                :has-stages="ticket.requestType === 'service_request'"
                :is-form-rules-available="
                  formRulesContext.hasServiceCatalogFormRules
                "
                :custom-rules="{ allowedWorklogAccess }"
                v-bind="tabComponentProps"
                :update-api="update"
                :single-field-rules-state-fn="singleFieldRulesStateFn"
                :default-outgoing-email-id="defaultOutgoingEmail.id"
                @relation-added="handleRelationCount"
                @relation-removed="handleRelationRemoved"
                @refresh="refreshTicket"
                @refresh-page="refreshPage"
                @solution-added="handleSolutionAdded"
                @edit-request-info-tab="handleShowEditFormModal"
              />
            </div>
          </div>
        </slot>
      </DetailView>
    </SuggestedFieldValuesProvider>
    <FlotoConfirmModal
      :open="showResolveConfirmModal"
      @hide="showResolveConfirmModal = false"
      @confirm="handleResolveTicket"
      @cancel="handleCancelResolveTicket"
    >
      <template v-slot:icon>
        <MIcon name="check" class="w-full text-secondary-green" size="2x" />
      </template>
      <template v-slot:message>
        {{ $t('ask_resolve_item', { item: $tc(moduleName) }) }}
      </template>
    </FlotoConfirmModal>
    <FlotoLoadError
      v-if="error"
      class="flex-1"
      :authorization-error="error.response && error.response.status === 403"
    />
    <!-- confirm modal for if ticket has secondarytickets and it gets closed -->
    <ConfirmCloseModal
      v-if="showConfirmCloseTicketModal"
      :module-name="moduleName"
      @confirm="update($event, true)"
      @hide="showConfirmCloseTicketModal = false"
    >
      <template v-if="ticket.hasSecondaryTickets" v-slot:message>
        {{
          $t('confirm_close_related', {
            resource: $tc(moduleName),
            resources: $tc(moduleName, 2),
          })
        }}
      </template>
    </ConfirmCloseModal>
    <NoteRules ref="noteDrawer" :create-fn="handleNoteAdded" />
    <DialogRulesConfirmModal
      :key="customRulesModuleName"
      ref="dialogRulesConfirmModalRef"
      :module-name="customRulesModuleName"
      @confirm="handleConfirmDialogRule"
    />
    <TicketFormEditModal
      v-if="formRulesContext.showEditFormModal"
      :un-touched-resource="ticket"
      :module-name="moduleName"
      :resource="formRulesContext.updatedResource"
      :open="formRulesContext.showEditFormModal"
      :submit-fn="handleSubmitEditFormFn"
      @cancel="handleCancel"
    />
  </FlotoContentLoader>
</template>

<script>
import { stageConstants } from '@utils/status'
import isEqual from 'lodash/isEqual'
import CloneDeep from 'lodash/cloneDeep'
import Intersection from 'lodash/intersection'
import Bus from '@utils/emitter'
import { FormComputed } from '@state/modules/form'
import { StatusComputed } from '@state/modules/status'
import { authComputed } from '@state/modules/auth'
import ConversationContainer from '@components/conversation/conversation-container.vue'
import TaskContainer from '@components/task/task-container.vue'
import WorkLogContainer from '@components/worklog/worklog-container'
import IntegrationContainer from '@components/integration/integration-container'
import AuditContainer from '@components/audit/audit-container'
import EmailNotificationContainer from '@components/email-notification/email-notification-container'
import RelationContainer from '@components/relation/relation-container'
import ApprovalContainer from '@components/approval/approval-container'
import NoteRules from '@components/note-rules/note-rules'
import SlaHistoryTab from '@components/sla-history/sla-history-tab.vue'
import DialogRulesConfirmModal from '@components/dialog-rules/dialog-rules'
import { CustomRulesComputed } from '@state/modules/custom-rules'
import {
  getRequestChangeApprovalDetailApi,
  getApprovalResourceDetailApi,
} from '@modules/my-approval/my-approval-api'
import { getRelationCountApi } from '@components/relation/relation-api'
import { getApi, updateApi, archiveApi } from '../ticket-api'
import ActionMenu from './action-menu'
import DetailView from './detail/detail.vue'
import RequestInformationTab from './request-information-tab'
import ProblemAnalysisTab from './problem-analysis-tab.vue'
import ChangeActivityTab from './change-activity-tab.vue'
import ConfirmCloseModal from './confirm-close-modal.vue'
import ChangeStagesContainer from './change-stages-container/change-stages-container'
import ChangeFormConsumer from './change-form-consumer.vue'
import CurrentWatchingUsers from './current-watching-users.vue'
import { getDisabledTabsForStage } from '../helpers/change-stage-tabs'
import SuggestedFieldsBtn from '@components/smart-suggestions/suggested-fields-btn'
import SuggestedFieldValuesProvider from '@components/providers/suggested-field-value-provider'
import {
  executionTypeMap,
  userTypeMap,
  eventTypeMap,
  formRulesSupportedModules,
} from '@data/form-rules'
import { getFormApi } from '@modules/form/form-api'
import { getServiceCatalogCustomRulesInfoApi } from '@modules/custom-rules/custom-rules-api'
import TicketFormEditModal from './ticket-form-edit-modal'
import { getDefaultOutgoingEmailApi } from '@src/components/conversation/api.js'
import { getSLASettingApi } from '@src/modules/sla/sla-api.js'
import { getApprovalSettingApi } from '@modules/approval/approval-api'
import RequestRatingView from './request-rating-view.vue'
export default {
  name: 'TicketDetailView',
  components: {
    DetailView,
    ActionMenu,
    ConfirmCloseModal,
    NoteRules,
    DialogRulesConfirmModal,
    ProblemAnalysisTab,
    ChangeStagesContainer,
    ChangeFormConsumer,
    CurrentWatchingUsers,
    SuggestedFieldsBtn,
    SuggestedFieldValuesProvider,
    TicketFormEditModal,
    SlaHistoryTab,
    RequestRatingView,
  },
  inject: {
    socketIOClientContext: { socket: undefined, connected: false },
    formRulesContext: 'formRulesContext',
  },
  inheritAttrs: false,
  props: {
    moduleName: { type: String, required: true },
    useTransition: { type: Boolean, default: false },
    resourceId: { type: [String, Number], required: true },
    onChange: { type: Function, default: undefined },
    disabled: { type: Boolean, default: false },
    backLink: { type: Object, default: undefined },
    // eslint-disable-next-line
    isPage: { type: Boolean, default: false },
    isApproval: { type: Boolean, default: false },
    approval: { type: Object, default: undefined },
    ongoingCallDetails: { type: Object, default: undefined },
    defaultSidebarVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      pendingChanges: null,
      ticket: {},
      parellelUsers: [],
      showConfirmCloseTicketModal: false,
      activeTabKey: this.$route.query.activeTab
        ? this.$route.query.activeTab
        : this.moduleName === this.$constants.CHANGE ||
          this.moduleName === this.$constants.RELEASE
        ? 'change_activity'
        : 'work',
      tabsRerenderCount: 1,
      error: null,
      loading: true,
      showResolveConfirmModal: false,
      allowedTabs: [],
      changeStage: undefined,
      solutionAddedData: undefined,
      relationCount: 0,
      ticketNoteRequiredFields: [],
      ticketDialogFields: null,
      allowedWorklogAccess: '',
      defaultOutgoingEmail: {},
      allowToEditDueBy: true,
      approvalSettings: {},
    }
  },
  computed: {
    ...authComputed,
    ...FormComputed,
    ...StatusComputed,
    ...CustomRulesComputed,
    // changeStage() {
    //   return (this[`${this.moduleName}FindStatusFromId`](this.ticket.statusId) || {}).stage
    // },
    noteRequiredFields() {
      return this.ticketNoteRequiredFields.map((i) => `${i}`)
    },
    closeStatusId() {
      if (this[`${this.moduleName}FindStatus`]) {
        if (
          this.moduleName === this.$constants.CHANGE ||
          this.moduleName === this.$constants.RELEASE
        ) {
          return (this[`${this.moduleName}FindStatus`]('closed', 'close') || {})
            .id
        }
        return (this[`${this.moduleName}FindStatus`]('closed') || {}).id
      }
      return undefined
    },
    openStatusId() {
      if (this[`${this.moduleName}FindStatus`]) {
        if (
          this.moduleName === this.$constants.CHANGE ||
          this.moduleName === this.$constants.RELEASE
        ) {
          if (this.ticket.prevStatusId) {
            return this.ticket.prevStatusId
          }
          return this[`${this.moduleName}FindStatus`](
            'in progress',
            stageConstants.PLANNING
          ).id
        }
        return this[`${this.moduleName}FindStatus`]('open').id
      }
      return undefined
    },
    isClosedStatusHiddenFromFormRule() {
      const hiddenStatuses =
        this.singleFieldRulesStateFn('statusId').hiddenOptionsKeys || []
      return (
        formRulesSupportedModules.indexOf(this.moduleName) >= 0 &&
        hiddenStatuses.indexOf(this.closeStatusId) >= 0
      )
    },
    resolveStatusId() {
      if (
        [this.$constants.REQUEST, this.$constants.PROBLEM].indexOf(
          this.moduleName
        ) >= 0 &&
        this[`${this.moduleName}FindStatus`]
      ) {
        return this[`${this.moduleName}FindStatus`]('resolved').id
      }
      return undefined
    },
    tabComponent() {
      return (this.allowedTabs.find((t) => t.id === this.activeTabKey) || {})
        .component
    },
    tabComponentProps() {
      const componentProps = {
        ...((this.allowedTabs.find((t) => t.id === this.activeTabKey) || {})
          .componentProps || {}),
        disabledByFormRules: (
          (this.fieldRulesState || {})[this.activeTabKey] || {}
        ).disable,
      }
      return componentProps
    },
    isTicketClosed() {
      return this.disabled || this.ticket.statusId === this.closeStatusId
    },
    isChangeDisabled() {
      let isChangeDisabled = false
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        const disabledKeys = getDisabledTabsForStage(this.changeStage)
        isChangeDisabled = disabledKeys.indexOf('field_update') >= 0
      }
      return isChangeDisabled
    },
    isTicketResolve() {
      return this.disabled || this.ticket.statusId === this.resolveStatusId
    },
    fieldLabels() {
      const fields = this[`${this.moduleName}Fields`]({
        only: ['subject', 'description'],
      })
      if (!fields.length) {
        return {}
      }
      const titleLabel = fields.find(
        ({ paramName }) => paramName === 'subject'
      ).title
      const descriptionLabel = fields.find(
        ({ paramName }) => paramName === 'description'
      ).title
      return {
        titleLabel,
        descriptionLabel,
      }
    },
    formRulesUserTypeFilter() {
      return [
        userTypeMap['all'],
        userTypeMap['technician'],
        userTypeMap['logged_in_user'],
      ]
    },
    customRulesModuleName() {
      if (this.ticket.serviceCatalogId) {
        return this.$constants.SERVICE_CATALOG
      }
      return this.moduleName
    },
    fieldRulesState() {
      return (this.formRulesContext || {}).fieldRulesState || {}
    },
  },
  watch: {
    resourceId: {
      immediate: true,
      handler: 'getTicket',
    },
    changeStage() {
      this.setTabs()
    },
    changeStatus(newValue) {
      if (
        (this.moduleName === this.$constants.CHANGE ||
          this.moduleName === this.$constants.RELEASE) &&
        this.ticket.statusId
      ) {
        this.changeStage = (
          this[`${this.moduleName}FindStatusFromId`](this.ticket.statusId) || {}
        ).stage
      }
    },
    'ticket.statusId': {
      immediate: true,
      handler() {
        if (
          (this.moduleName === this.$constants.CHANGE ||
            this.moduleName === this.$constants.RELEASE) &&
          this.ticket.statusId
        ) {
          this.changeStage = (
            this[`${this.moduleName}FindStatusFromId`](this.ticket.statusId) ||
            {}
          ).stage
        }
      },
    },
  },
  created() {
    const createTaskHandler = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (this.activeTabKey !== 'task') {
        this.activeTabKey = 'task'
        this.$nextTick(() => Bus.$emit('create-task'))
      }
    }
    const createWorklogHandler = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (this.activeTabKey !== 'work_log') {
        this.activeTabKey = 'work_log'
        this.$nextTick(() => Bus.$emit('create-worklog'))
      }
    }
    const createIntegrationHandler = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (this.activeTabKey !== 'integration') {
        this.activeTabKey = 'integration'
        this.$nextTick(() => Bus.$emit('create-integration'))
      }
    }
    const closeTicketHandler = (e) => {
      this.handleCloseTicket()
    }
    const conversationHandler = (e, k, command, nextCommand) => {
      let availableCommands = []
      if (this.$route.params.ticketType === this.$constants.REQUEST) {
        availableCommands = [
          'reply',
          'collaborate',
          'note',
          'solution',
          'diagnosis',
        ]
      }
      if (this.$route.params.ticketType === this.$constants.PROBLEM) {
        availableCommands = ['collaborate', 'note', 'solution']
      }
      if (
        this.$route.params.ticketType === this.$constants.CHANGE ||
        this.$route.params.ticketType === this.$constants.RELEASE
      ) {
        availableCommands = ['collaborate', 'note']
      }
      if (
        availableCommands.indexOf(nextCommand) >= 0 &&
        this.activeTabKey !== 'work'
      ) {
        this.activeTabKey = 'work'
      }
    }
    Bus.$on('create-task', createTaskHandler)
    Bus.$on('create-worklog', createWorklogHandler)
    Bus.$on('create-integration', createIntegrationHandler)
    Bus.$on('close-ticket', closeTicketHandler)
    Bus.$on('conversation', conversationHandler)
    if (this.socketIOClientContext.socket) {
      this.socketIOClientContext.socket.on(
        'connect',
        this.notifyOtherUserOnVisit
      )
      this.socketIOClientContext.socket.on(
        'connect_error',
        this.sendPageLeaveEvent
      )
      this.socketIOClientContext.socket.on(
        `${this.socketIOClientContext.eventPrefix}-presence_change`,
        this.updateParellelUsers
      )
    }

    this.$once('hook:beforeDestroy', () => {
      Bus.$off('create-task', createTaskHandler)
      Bus.$off('create-worklog', createWorklogHandler)
      Bus.$off('create-integration', createIntegrationHandler)
      Bus.$off('close-ticket', closeTicketHandler)
      Bus.$off('conversation', conversationHandler)

      if (this.socketIOClientContext.socket) {
        this.socketIOClientContext.socket.off(
          'connect',
          this.sendPageVisitEvent
        )
        this.socketIOClientContext.socket.off(
          'connect_error',
          this.sendPageLeaveEvent
        )

        this.socketIOClientContext.socket.off(
          `${this.socketIOClientContext.eventPrefix}-presence_change`,
          this.updateParellelUsers
        )

        this.sendPageLeaveEvent()
      }
    })
    this.getRelationModuleCount()
    this.getSLASetting()
    if (this.moduleName === this.$constants.REQUEST) {
      this.getDefaultOutgoingEmail()
    }
  },
  mounted() {
    if (
      this.formRulesContext.isFormRuleQualified({
        executionTypes: [
          executionTypeMap['create_and_edit'],
          executionTypeMap['edit'],
        ],
        userTypes: this.formRulesUserTypeFilter,
        eventType: eventTypeMap['load'],
      })
    ) {
      this.formRulesContext.applyFormRules(
        { ...this.ticket },
        { viewOnlyMode: true },
        {
          executionTypes: [
            executionTypeMap['create_and_edit'],
            executionTypeMap['edit'],
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['load'],
        }
      )
    }
  },
  methods: {
    singleFieldRulesStateFn(paramName) {
      return (this.fieldRulesState || {})[paramName] || {}
    },
    updateParellelUsers(data) {
      if (
        data.resourceType === this.moduleName &&
        data.resourceId === this.resourceId
      ) {
        const currentUserId = this.user.id
        this.parellelUsers = Object.freeze(
          data.users.filter((id) => id !== currentUserId)
        )
      }
    },
    getRelationModuleCount() {
      return getRelationCountApi(this.moduleName, this.resourceId).then(
        (data) => {
          Object.values(data).filter((value) => {
            this.relationCount = this.relationCount + value
          })
          this.setTabs()
        }
      )
    },
    handleRelationCount() {
      this.relationCount = 0
      this.getRelationModuleCount()
    },
    handleRelationRemoved() {
      this.relationCount = 0
      this.getRelationModuleCount()
    },
    integrationTab() {
      const integration = {
        id: 'integration',
        name: this.$tc('integration'),
        component: IntegrationContainer,
        componentProps: {
          alwaysEditable: true,
          stickySearch: true,
          offsetTop: 38,
        },
      }
      if (
        this.moduleName === this.$constants.REQUEST &&
        this.ticket.showIntegrationTab
      ) {
        return integration
      }
    },
    workTab() {
      const work = { id: 'work' }
      if (this.moduleName === this.$constants.REQUEST) {
        work.name = this.$t('work')
        work.component = ConversationContainer
        work.componentProps = {
          allowKnowledges: true,
          allowTemplates: true,
          usePrivateIndicator: true,
          richBoxRows: 8,
          alwaysEditable: true,
          stickySearch: true,
          offsetTop: 38,
          ongoingCallDetails: this.ongoingCallDetails,
          allowSaveInDraft: true,
        }
      }
      if (this.moduleName === this.$constants.PROBLEM) {
        work.name = this.$t('analysis')
        work.component = ProblemAnalysisTab
        work.componentProps = {
          allowSaveInDraft: true,
        }
      }
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        work.name = this.$tc('collaboration')
        work.component = ConversationContainer
        work.componentProps = {
          stickySearch: true,
          alwaysEditable: true,
          offsetTop: 38,
          allowSaveInDraft: true,
        }
      }
      return work
    },
    setTabs(activeModule = undefined) {
      let tabs = [
        ...(this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
          ? [
              {
                id: 'change_activity',
                name: this.$tc('planning'),
                component: ChangeActivityTab,
                componentProps: {
                  changeStage: this.changeStage,
                },
              },
            ]
          : []),
        {
          ...this.workTab(),
        },
        {
          id: 'task',
          name: this.$tc('task', 2),
          component: TaskContainer,
          componentProps: { stickySearch: true, offsetTop: 38 },
        },
        {
          id: 'approval',
          name: this.$tc('approval', 2),
          component: ApprovalContainer,
        },
        ...(this.ticket.serviceCatalogId
          ? [
              {
                id: 'request_information',
                name: `${this.$tc('request')} ${this.$t('information')}`,
                component: RequestInformationTab,
                componentProps: {
                  disabled:
                    (this.ticket.approvalStatus === 'pre_approved' ||
                      this.ticket.approvalStatus === 'approved') &&
                    !this.approvalSettings.allowToUpdateSRFieldAfterApproval,
                },
              },
            ]
          : []),
        {
          id: 'relation',
          name: `${this.$tc('relation', 2)} (${this.relationCount})`,
          component: RelationContainer,
          componentProps: {
            defaultActive: activeModule,
            stickySearch: true,
            offsetTop: 38,
          },
        },
        {
          id: 'sla_history',
          name: `${this.$tc('sla')}`,
          component: SlaHistoryTab,
        },
        {
          id: 'work_log',
          name: this.$t('work_log'),
          component: WorkLogContainer,
          componentProps: {
            alwaysEditable: true,
            stickySearch: true,
            offsetTop: 38,
          },
        },
        {
          id: 'activity',
          name: this.$tc('audit_trail'),
          component: AuditContainer,
        },
        {
          id: 'email_notification',
          name: this.$tc('notification', 2),
          component: EmailNotificationContainer,
          componentProps: {
            alwaysEditable: true,
            stickySearch: true,
            offsetTop: 38,
          },
        },
        {
          ...this.integrationTab(),
        },
      ]
      if (
        (!this.ticket.approvalStatus ||
          this.ticket.approvalStatus === 'pre_approved') &&
        'showApprovalTab' in this.ticket &&
        !this.ticket.showApprovalTab
      ) {
        tabs = tabs.filter((t) => t.id !== 'approval')
      }
      if (
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
      ) {
        const disabledKeys = getDisabledTabsForStage(this.changeStage)
        tabs = tabs.map((t) => {
          return {
            ...t,
            componentProps: {
              ...(t.componentProps || {}),
              disabled: disabledKeys.indexOf(t.id) >= 0,
            },
          }
        })
      }
      this.allowedTabs = Object.freeze(tabs.filter((e) => e.id))
    },
    handleNoteAdded(data) {
      return this.update(
        {
          ...data,
          ...this.pendingChanges,
        },
        true,
        false
      ).then(() => {
        this.$refs.noteDrawer.closeForm()
        this.refreshTicket(['work'])
      })
    },
    handleConfirmDialogRule() {
      return this.update(
        {
          ...this.pendingChanges,
        },
        true,
        false,
        true
      ).then(() => {
        this.$refs.dialogRulesConfirmModalRef.handleHide()
      })
    },
    prepairSolutionDataForKnowledge(solution) {
      return {
        subject: this.ticket.subject,
        content: solution.text,
        fileAttachments: solution.fileAttachments,
      }
    },
    handleResolveTicket() {
      this.update({ statusId: this.resolveStatusId })
        .then((data) => {
          return data
        })
        .finally(() => {
          if (this.solutionAddedData) {
            return this.$router.push(
              this.$modules.getModuleRoute('knowledge', 'create', {
                params: this.solutionAddedData
                  ? {
                      ...this.prepairSolutionDataForKnowledge(
                        this.solutionAddedData
                      ),
                    }
                  : {},
              })
            )
          }
        })
    },
    handleCancelResolveTicket() {
      if (this.solutionAddedData) {
        return this.$router.push(
          this.$modules.getModuleRoute('knowledge', 'create', {
            params: this.solutionAddedData
              ? {
                  ...this.prepairSolutionDataForKnowledge(
                    this.solutionAddedData
                  ),
                }
              : {},
          })
        )
      }
    },
    handleSolutionAdded(solutionAddedData) {
      this.solutionAddedData = solutionAddedData
      if (this.isTicketClosed || this.isTicketResolve) {
        if (solutionAddedData) {
          this.$router.push(
            this.$modules.getModuleRoute('knowledge', 'create', {
              params: solutionAddedData
                ? { ...this.prepairSolutionDataForKnowledge(solutionAddedData) }
                : {},
            })
          )
        }
        return true
      }
      this.showResolveConfirmModal = true
    },
    notifyOtherUserOnVisit() {
      if (!this.isPage) {
        return
      }
      if (this.isApproval) {
        return
      }
      if (!['request'].includes(this.moduleName)) {
        return
      }
      if (this.socketIOClientContext.connected) {
        this.sendPageVisitEvent()
      }
    },
    sendPageVisitEvent() {
      this.socketIOClientContext.socket.emit(
        `${this.socketIOClientContext.eventPrefix}-visit_page`,
        {
          resourceType: this.moduleName,
          resourceId: this.resourceId,
        }
      )
    },
    sendPageLeaveEvent() {
      this.socketIOClientContext.socket.emit(
        `${this.socketIOClientContext.eventPrefix}-leave_page`,
        {
          resourceType: this.moduleName,
          resourceId: this.resourceId,
        }
      )
    },
    getServiceForm() {
      getFormApi(
        this.$constants.SERVICE_CATALOG,
        this.ticket.serviceCatalogId,
        {
          archived: true,
        },
        this.isPortalLogin
      ).then((data) => {
        this.formRulesContext.setServiceCatalogFormFieldsForDetails(data.fields)
      })
    },
    getCustomRules(serviceCatalogId) {
      if (serviceCatalogId) {
        getServiceCatalogCustomRulesInfoApi([serviceCatalogId]).then((data) => {
          if (data[serviceCatalogId]) {
            this.ticketNoteRequiredFields =
              data[serviceCatalogId].noteRules || []
            this.ticketDialogFields = data[serviceCatalogId].dialogRules || []
            this.allowedWorklogAccess =
              data[serviceCatalogId].worklogAccessRules || []
          } else {
            this.ticketNoteRequiredFields =
              this[`${this.customRulesModuleName}NoteRequiredFields`] || []
            this.allowedWorklogAccess =
              this[`${this.customRulesModuleName}WorklogAccess`] || ''
          }
        })
      } else {
        this.ticketNoteRequiredFields =
          this[`${this.customRulesModuleName}NoteRequiredFields`] || []
        this.allowedWorklogAccess =
          this[`${this.customRulesModuleName}WorklogAccess`] || ''
      }
    },
    getTicket() {
      this.loading = true
      this.ticket = {}
      const api = this.isApproval
        ? [this.$constants.REQUEST, this.$constants.CHANGE].indexOf(
            this.moduleName
          ) >= 0
          ? getRequestChangeApprovalDetailApi
          : getApprovalResourceDetailApi
        : getApi
      return api(this.moduleName, this.resourceId, this.isPortalLogin)
        .then((ticket) => {
          this.loading = false
          this.ticket = ticket
          this.$emit('resource', ticket)
          this.notifyOtherUserOnVisit()
          if (ticket.serviceCatalogId) {
            this.getServiceForm()
            this.formRulesContext.fetchServiceCatalogFormRules(
              ticket.serviceCatalogId
            )
            getApprovalSettingApi().then((data) => {
              this.approvalSettings = data
              this.setTabs()
            })
          } else {
            this.setTabs()
          }
          this.getCustomRules(ticket.serviceCatalogId)
          return ticket
        })
        .catch((e) => {
          this.error = e
          this.loading = false
          this.$router.replace(
            this.$currentModule.getRoute('', {
              params: { ticketType: this.$route.params.ticketType },
            })
          )
        })
    },
    refreshPage() {
      this.activeTabKey =
        this.moduleName === this.$constants.CHANGE ||
        this.moduleName === this.$constants.RELEASE
          ? 'change_activity'
          : 'work'
      this.refreshTicket()
    },
    refreshTicket(reRenderTabs = undefined) {
      return getApi(this.moduleName, this.resourceId).then((ticket) => {
        this.ticket = ticket
        if (reRenderTabs && reRenderTabs.indexOf(this.activeTabKey) >= 0) {
          this.tabsRerenderCount = this.tabsRerenderCount + 1
        }
        this.setTabs()
        if (
          reRenderTabs &&
          reRenderTabs.indexOf('approval') >= 0 &&
          this.allowedTabs.find((t) => t.id === 'approval') &&
          this.activeTabKey !== 'approval'
        ) {
          this.activeTabKey = 'approval'
        }
        return ticket
      })
    },
    handleCloseTicket() {
      this.update({ statusId: this.closeStatusId })
    },
    handleReopenTicket() {
      if (this.noteRequiredFields.indexOf('reopen') >= 0) {
        this.pendingChanges = { statusId: this.openStatusId }
        this.$refs.noteDrawer.openForm()
      } else {
        this.update({ statusId: this.openStatusId }, false, false)
      }
    },
    handleCheckUpdatedFieldAvailableInNoteRequiredField(
      noteRequiredFields,
      change
    ) {
      let isUpdated = false
      noteRequiredFields.forEach((field) => {
        if (field in change) {
          const isSame = Array.isArray(change[field])
            ? !isEqual(change[field], this.ticket[field])
            : change[field] !== this.ticket[field]

          if (isSame) {
            isUpdated = true
            return isUpdated
          }
        }
      })
      return isUpdated
    },
    update(
      change,
      forceUpdate = false,
      checkFormRules = true,
      ignoreDialog = false
    ) {
      if (Object.keys(change).length) {
        // check form rules
        if (
          checkFormRules &&
          this.formRulesContext.isFormRuleQualified({
            executionTypes: [
              executionTypeMap['create_and_edit'],
              executionTypeMap['edit'],
            ],
            userTypes: this.formRulesUserTypeFilter,
            eventType: eventTypeMap['change'],
            triggerFields: Object.keys(change),
          })
        ) {
          this.formRulesContext.applyFormRules(
            { ...this.ticket, ...change },
            {},
            {
              executionTypes: [
                executionTypeMap['create_and_edit'],
                executionTypeMap['edit'],
              ],
              userTypes: this.formRulesUserTypeFilter,
              eventType: eventTypeMap['change'],
              triggerFields: Object.keys(change),
            }
          )
          return
        }
        // check note rules
        const noteRequiredFields = CloneDeep(this.noteRequiredFields)
        if (
          (this.moduleName === this.$constants.CHANGE ||
            this.moduleName === this.$constants.RELEASE) &&
          'statusId' in change
        ) {
          const status =
            this[`${this.moduleName}FindStatusFromId`](change.statusId) || {}
          let statusString = `${status.stage}@${status.name.toLowerCase()}`
          if (status.systemName) {
            statusString = `${status.stage}@${status.systemName.toLowerCase()}`
          }
          if (noteRequiredFields.indexOf(statusString) >= 0) {
            noteRequiredFields.push('statusId')
          }
        }
        if (
          Intersection(noteRequiredFields, Object.keys(change)).length &&
          forceUpdate === false &&
          this.handleCheckUpdatedFieldAvailableInNoteRequiredField(
            noteRequiredFields,
            change
          )
        ) {
          this.pendingChanges = change
          this.$refs.noteDrawer.openForm()
          return Promise.resolve()
        } else {
          const checkIsDialogRulesQualifide =
            this.$refs.dialogRulesConfirmModalRef &&
            this.$refs.dialogRulesConfirmModalRef.checkIsDialogRulesQualifide(
              change,
              this.ticketDialogFields
            )
          if (!forceUpdate && checkIsDialogRulesQualifide) {
            if (ignoreDialog) {
              this.$refs.dialogRulesConfirmModalRef.handleHide()
            } else {
              this.$refs.dialogRulesConfirmModalRef.handleShow()
              this.pendingChanges = change
              return Promise.resolve()
            }
          }
        }
        if (
          forceUpdate !== true &&
          'statusId' in change &&
          change.statusId === this.closeStatusId &&
          checkFormRules
          // this.ticket.hasSecondaryTickets
        ) {
          this.showConfirmCloseTicketModal = true
          return Promise.resolve()
        }
        return updateApi(
          this.moduleName,
          {
            id: this.ticket.id,
            ...change,
          },
          this.isPortalLogin
        )
          .then((data) => {
            this.pendingChanges = null
            this.ticket = { ...this.ticket, ...data }
            if (this.onChange) {
              this.onChange(data)
            } else {
              if (!('startDate' in change || 'endDate' in change)) {
                this.refreshTicket(['request_information'])
              }
            }
            return data
          })
          .catch((e) => {
            if (this.$refs.dialogRulesConfirmModalRef) {
              this.$refs.dialogRulesConfirmModalRef.handleHide()
            }
          })
      }
      return this.refreshTicket(['approval'])
    },
    handleArchiveTicket() {
      archiveApi(this.moduleName, this.ticket.id).then(() =>
        this.$emit('delete')
      )
    },
    identifiedAsProblem() {
      this.setTabs('problem')
      this.activeTabKey = 'relation'
    },
    purchaseRequest() {
      this.setTabs('purchase')
      this.activeTabKey = 'relation'
    },
    handleSubmitEditFormFn(change) {
      return this.update(change, false, false).then((data) => {
        this.formRulesContext.handleHideEditFormModal()
        this.formRulesContext.setInitState()
        this.$refs && this.$refs.ticketDetailRef.handleExectuteFormRules(data)
      })
      // return updateApi(
      //   this.moduleName,
      //   {
      //     id: this.ticket.id,
      //     ...change,
      //   },
      //   this.isPortalLogin
      // ).then((data) => {
      //   this.pendingChanges = null
      //   this.ticket = { ...this.ticket, ...data }
      //   if (this.onChange) {
      //     this.onChange(data)
      //   }
      //   this.formRulesContext.handleHideEditFormModal()
      //   return data
      // })
    },
    handleCancel() {
      this.formRulesContext.handleHideEditFormModal()
      this.formRulesContext.setInitState()
      this.$refs &&
        this.$refs.ticketDetailRef.handleExectuteFormRules(this.ticket)
    },
    handleShowEditFormModal() {
      this.formRulesContext.handleShowEditFormModal(this.ticket)
    },
    getDefaultOutgoingEmail() {
      return getDefaultOutgoingEmailApi(this.moduleName, this.resourceId).then(
        (data) => {
          this.defaultOutgoingEmail = data
        }
      )
    },
    getSLASetting() {
      getSLASettingApi().then(
        (data) => (this.allowToEditDueBy = data.allowToEditDueBy)
      )
    },
  },
}
</script>
