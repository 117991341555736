var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MDropdown',{ref:"dropdownMenu",on:{"show":_vm.handleActionMenuShow},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"ml-1",attrs:{"id":"action-btn","variant":"neutral-lighter","shadow":false,"shape":"circle"}},[_c('MIcon',{attrs:{"name":"ellipsis-v","size":"2x"}})],1)]},proxy:true},{key:"menu",fn:function(){return [_c('MMenu',{staticClass:"action-menu"},[(!_vm.disabled && _vm.allowAskForApproval)?_c('MMenuItem',{attrs:{"id":"ask-for-approval"}},[_c('ApprovalAction',{attrs:{"module-name":_vm.moduleName,"parent-id":_vm.ticket.id,"resource-name":_vm.ticket.name},on:{"trigger":_vm.hide,"done":function($event){return _vm.$emit('approval-changed')}}})],1):_vm._e(),(!_vm.disabled && _vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"split-request"}},[_c('SplitRequestAction',{attrs:{"module-name":_vm.moduleName,"resource":_vm.ticket},on:{"trigger":_vm.hide}})],1):_vm._e(),(!_vm.ticket.spam && !_vm.disabled && _vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"mark-as-spam"}},[_c('MarkAsSpam',{attrs:{"module-name":_vm.moduleName,"bind-shortcut":""},on:{"trigger":_vm.hide,"done":function($event){return _vm.$emit('update', { spam: true })}}})],1):_vm._e(),(!_vm.disabled && _vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"add-watcher"}},[_c('AddWatcher',{attrs:{"resource-id":_vm.ticket.id,"module-name":_vm.moduleName},on:{"trigger":_vm.hide}})],1):_vm._e(),(!_vm.disabled && _vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"watch"}},[_c('Watch',{attrs:{"is-watching":_vm.isUserWatching,"bind-shortcut":""},on:{"watch":function($event){return _vm.handleUpdateWatcher(Object.assign({}, _vm.watcher,
              {technicians: _vm.watcher.technicians.concat( [_vm.user.id])}))},"unwatch":function($event){_vm.handleUpdateWatcher(Object.assign({}, _vm.watcher,
              {technicians: _vm.watcher.technicians.filter(function (e) { return e !== _vm.user.id; })}))},"done":_vm.hide}})],1):_vm._e(),(
          _vm.allowSendFeedbackManually &&
          !_vm.disabled &&
          _vm.moduleName === _vm.$constants.REQUEST
        )?_c('MMenuItem',{attrs:{"id":"ask-for-feedback"}},[_c('SendFeedback',{attrs:{"resource-id":_vm.ticket.id},on:{"trigger":_vm.hide}})],1):_vm._e(),(!_vm.disabled && _vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"scenario"}},[_c('Scenario',{attrs:{"title":((_vm.ticket.name) + ": " + (_vm.ticket.subject)),"module-name":_vm.moduleName,"bind-shortcut":"","resource-id":_vm.ticket.id},on:{"trigger":_vm.hide}})],1):_vm._e(),(_vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"status-transition"}},[_c('TransitionDrawer',{attrs:{"resource":_vm.ticket,"module-name":_vm.moduleName,"type":"status"},on:{"trigger":_vm.hide},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var open = ref.open;
return [_c('div',{on:{"click":open}},[_c('MIcon',{staticClass:"action-menu-icon",attrs:{"name":"exchange"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('status'))+" "+_vm._s(_vm.$t('transition'))+" ")])],1)]}}],null,false,2801713431)})],1):_vm._e(),(_vm.moduleName === _vm.$constants.REQUEST)?_c('MMenuItem',{attrs:{"id":"assignment-transition"}},[_c('TransitionDrawer',{attrs:{"resource":_vm.ticket,"module-name":_vm.moduleName,"type":"assignment"},on:{"trigger":_vm.hide},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var open = ref.open;
return [_c('div',{on:{"click":open}},[_c('MIcon',{staticClass:"action-menu-icon",attrs:{"name":"exchange"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('assignment'))+" "+_vm._s(_vm.$t('transition'))+" ")])],1)]}}],null,false,912989360)})],1):_vm._e(),(
          !_vm.isTicketClosed &&
          !_vm.isTicketResolve &&
          _vm.moduleName === _vm.$constants.REQUEST &&
          _vm.hasRequestModule &&
          _vm.hasServiceCatalogModule
        )?_c('MMenuItem',{attrs:{"id":"converion-action"}},[_c('ConversionDrawer',{attrs:{"module-name":_vm.moduleName,"resource":_vm.ticket},on:{"trigger":_vm.hide,"refresh":function($event){return _vm.$emit('refresh')}}})],1):_vm._e(),_c('MMenuItem',{attrs:{"id":"archive"}},[_c('Archive',{attrs:{"resource-id":_vm.ticket.id,"module-name":_vm.moduleName,"bind-shortcut":""},on:{"trigger":_vm.hide,"done":_vm.handleArchive}})],1),_c('MMenuItem',{attrs:{"id":"print"}},[_c('Print',{attrs:{"resource-id":_vm.ticket.id,"service-catalog-id":_vm.ticket.serviceCatalogId,"module-name":_vm.moduleName},on:{"trigger":_vm.hide}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }