<template>
  <a @click="toggleWatch">
    <MIcon :name="isWatching ? 'user-slash' : 'star'" />
    <span class="ml-1 action-menu-text">
      {{ $t(isWatching ? 'unwatch' : 'watch') }}
    </span>
  </a>
</template>

<script>
import Bus from '@utils/emitter'

export default {
  name: 'Watch',
  props: {
    isWatching: { type: Boolean, default: false },
    bindShortcut: { type: Boolean, default: false },
  },
  data() {
    this.watchHandler = this.toggleWatch.bind(this)
    return {}
  },
  watch: {
    isWatching: {
      immediate: true,
      handler(newValue) {
        if (newValue === false) {
          this.bindWatchShortcut()
        } else {
          this.unbindWatchShortcut()
        }
      },
    },
  },
  methods: {
    bindWatchShortcut() {
      if (this.bindShortcut) {
        Bus.$on('watch', this.watchHandler)
        this.$once('hook:beforeDestroy', () => {
          Bus.$off('watch', this.watchHandler)
        })
      }
    },
    unbindWatchShortcut() {
      Bus.$off('watch', this.watchHandler)
    },
    toggleWatch() {
      const event = this.isWatching ? 'unwatch' : 'watch'
      this.$emit(event)
      this.$emit('done')
    },
  },
}
</script>
