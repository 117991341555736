import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      titleFn(route, translator, pluralTranslator) {
        return translator('user_survey')
      },
      nonLinked: true,
    },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "user-survey" */ './views/list')
          ),
        meta: {},
      },

      {
        path: 'schedule-survey',
        name: `${routeNamePrefix}.schedule-survey`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "user-survey" */ './components/schedule-survey/list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('schedule_survey')}`
          },
        },
      },
      {
        path: 'schedule-survey-form',
        name: `${routeNamePrefix}.schedule-survey-form`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "user-survey" */ './components/schedule-survey/survey-form-design'
            )
          ),
      },

      {
        path: 'create-schedule-survey',
        name: `${routeNamePrefix}.create-schedule-survey`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "user-survey" */ './components/schedule-survey/create'
            )
          ),
      },
      {
        path: 'schedule-survey/:id',
        name: `${routeNamePrefix}.edit-schedule-survey`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "user-survey" */ './components/schedule-survey/edit'
            )
          ),
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.view`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "user-survey" */ './views/view')
          ),
      },
    ],
  },
]
