import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        meta: {
          public: true,
          layout: 'LoginLayout',
        },
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "user-survey-response" */ './views/survey-response-page'
            )
          ),
      },
    ],
  },
]
