<template>
  <div class="flex items-center mt-4">
    <div class="background-icon p-2 rounded mr-3">
      <MIcon name="tags" size="lg" class="text-white" />
    </div>
    <MTag
      v-if="value.spam"
      variant="error"
      :confirmable="true"
      :closable="!disabled"
      :ok-text="okText"
      :cancel-text="cancelText"
      class="mx-1"
      @close="handleChange({ spam: false })"
    >
      <template v-slot:confirm-title>
        {{ $t('confirm_remove_item', { item: $t('spam') }) }}
      </template>
      {{ $t('spam') }}
    </MTag>
    <MTag
      v-if="value.vipRequest"
      variant="primary"
      :closable="false"
      :confirmable="false"
      class="uppercase cursor-text mx-1"
    >
      {{ $t('vip') }}
    </MTag>
    <MTag
      v-if="value.knownError === 'true'"
      variant="warning"
      :closable="false"
      :confirmable="false"
      class="uppercase cursor-text mx-1"
    >
      {{ $t('known_error') }}
    </MTag>
    <MTag
      v-if="value.identifiedAsProblem && hasProblemModule"
      variant="warning"
      :closable="false"
      :confirmable="false"
      class="mx-1"
      @click="$emit('identifiedAsProblem')"
    >
      {{ $t('identified_as_problem') }}
    </MTag>
    <MTag
      v-if="value.purchaseRequest && hasPurchaseModule"
      variant="warning"
      :closable="false"
      :confirmable="false"
      class="mx-1"
      @click="$emit('purchaseRequest')"
    >
      {{ $tc('purchase') }} {{ $tc('request') }}
    </MTag>
    <MTag
      v-if="value.reopenCount"
      variant="error"
      :closable="false"
      :confirmable="false"
      class="cursor-text mx-1"
    >
      {{ value.reopenCount }} {{ $tc('times', value.reopenCount) }}
      {{ $t('reopened') }}
    </MTag>
    <ApprovalStatusIcon
      v-if="value.approvalStatus"
      class="cursor-text flex mx-1"
      :status="value.approvalStatus"
    >
      <template v-slot:prefix-text> {{ $tc('approval') }}: </template>
    </ApprovalStatusIcon>
    <MTag
      v-if="value.slaViolated"
      variant="error"
      class="cursor-text mx-1"
      :closable="false"
      :confirmable="false"
    >
      {{ $t('sla') }} {{ $t('violated') }}
    </MTag>
    <MTag
      v-if="moduleName === $constants.REQUEST && value.olaViolated"
      variant="error"
      class="cursor-text mx-1"
      :closable="false"
      :confirmable="false"
    >
      {{ $t('ola') }} {{ $t('violated') }}
    </MTag>
    <MTag
      v-if="moduleName === $constants.REQUEST && value.responseDueViolated"
      variant="error"
      class="cursor-text mx-1"
      :closable="false"
      :confirmable="false"
    >
      {{ $t('first') }} {{ $t('response') }} {{ $t('sla') }}
      {{ $t('violated') }}
    </MTag>
    <MTag
      v-if="value.dueBy && isOverDue"
      variant="error"
      class="cursor-text mx-1"
      :closable="false"
      :confirmable="false"
    >
      {{ $t('overdue') }}
    </MTag>
    <MTag
      v-if="moduleName === $constants.REQUEST && value.olaDueBy && isOlaOverDue"
      variant="error"
      class="cursor-text mx-1"
      :closable="false"
      :confirmable="false"
    >
      {{ $tc('ola') }} {{ $t('overdue') }}
    </MTag>
    <MTag
      v-if="
        moduleName === $constants.REQUEST &&
        value.responseDue &&
        isResponseOverDue
      "
      variant="error"
      class="cursor-text mx-1"
      :closable="false"
      :confirmable="false"
    >
      {{ $t('first') }} {{ $t('response') }} {{ $t('overdue') }}
    </MTag>
    <MTag
      v-if="isApproval"
      variant="neutral-lighter"
      :closable="false"
      :confirmable="false"
      class="cursor-text mx-1"
    >
      {{ $tc('approval') }} {{ $tc('requester') }} :
      <FlotoUserDrawer :user-id="approval.approvalRequesterId" />
    </MTag>
    <FlotoTagsPicker
      v-if="hiddenFields.indexOf('tags') === -1"
      :value="value.tags"
      :required="isRequiredFn('tags')"
      :confirm-before-remove="true"
      variant="neutral-lighter"
      :disabled="disabled || singleFieldRulesStateFn('tags').disable"
      @change="handleChange({ tags: $event })"
    />
  </div>
</template>

<script>
import { isOverDue as checkOverdue } from '@utils/due-by'
import { LicenseComputed } from '@state/modules/license'
import { StatusComputed } from '@state/modules/status'
import ApprovalStatusIcon from '@components/approval/components/approval-status-icon'
export default {
  name: 'TagsRow',
  components: { ApprovalStatusIcon },
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    isApproval: { type: Boolean, default: false },
    approval: { type: Object, default: undefined },
    moduleName: { type: String, required: true },
    okText: {
      type: String,
      default() {
        return this.$tc('yes')
      },
    },
    cancelText: {
      type: String,
      default() {
        return this.$tc('cancel')
      },
    },
    hiddenFields: {
      type: Array,
      default() {
        return []
      },
    },
    isRequiredFn: {
      type: Function,
      default: (e) => e,
    },
    singleFieldRulesStateFn: {
      type: Function,
      default: (e) => e,
    },
  },
  computed: {
    ...StatusComputed,
    ...LicenseComputed,
    hasProblemModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.PROBLEM) >= 0
      )
    },
    hasPurchaseModule() {
      return (
        this.availableModulesInLicense.indexOf(this.$constants.PURCHASE) >= 0
      )
    },
    isSlaRunning() {
      const status =
        (this[`${this.moduleName}Status`] || []).find(
          (s) => s.key === this.resource.statusId
        ) || {}
      if (status.runSla) {
        return true
      }
      return false
    },
    isOverDue() {
      const slaRunning =
        this.moduleName === this.$constants.PROBLEM ? true : this.isSlaRunning
      if (slaRunning && checkOverdue(this.value.dueBy)) {
        return true
      }
      return false
    },
    isOlaOverDue() {
      if (checkOverdue(this.value.olaDueBy)) {
        return true
      }
      return false
    },
    isResponseOverDue() {
      if (this.isSlaRunning && checkOverdue(this.value.responseDue)) {
        if (this.value.firstResponseTime === 0) {
          return true
        }
        return false
      }
      return false
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>
