var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{ref:"crudContainerRef",attrs:{"fetch-fn":_vm.fetchFn,"columns":_vm.tableColumns,"show-no-data":false,"scrollable":false,"as-table":""},scopedSlots:_vm._u([{key:"add-controls",fn:function(){return [_c('MRow',[_c('MCol',{staticClass:"mb-2",attrs:{"siz":12}},[_c('h5',{staticClass:"text-ellipsis font-normal"},[_vm._v(" "+_vm._s(_vm.fieldName)+" "+_vm._s(_vm.$tc('transition'))+" ")]),_c('TransitionBar',{attrs:{"stack-data":_vm.transitionBarData}})],1)],1)]},proxy:true},{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"performerName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.performerName ? item.performerName : _vm.$tc('system'))+" ")])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(_vm._f("datetime")(item.createdAt,undefined, true))+" ")])]}},{key:"transition",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.oldTransitionFieldName)+" -> "+_vm._s(item.newTransitionFieldName)+" ")])]}},{key:"duration",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(_vm._f("duration")(item.duration))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }