<template>
  <div class="flex flex-col flex-1">
    <div class="stacked-bar shadow border-r-4">
      <div
        v-for="stack in stacks"
        :key="stack.guid"
        class="stack"
        :style="{ background: stack.color, width: stack.width }"
        :title="stack.tooltip"
      />
    </div>
    <div class="ligend flex justify-center m-2 flex-wrap">
      <span v-for="legend in stacks" :key="legend.guid" class="mr-3">
        <FlotoDot v-if="legend.color" :bg="legend.color" />
        {{ legend.title }}
      </span>
    </div>
  </div>
</template>

<script>
import { generateId } from '@utils/id'
import { getRandomColor } from '@utils/color'
export default {
  name: 'TransitionBar',
  props: {
    stackData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      stacks: [],
    }
  },
  watch: {
    stackData: {
      immediate: true,
      handler: 'prepairStack',
    },
  },
  methods: {
    prepairStack() {
      const total = this.stackData.reduce(
        (t, item) => t + item.totalDuration,
        0
      )
      const getWidth = (value) => {
        return (value * 100) / total
      }
      this.stacks = this.stackData.map((s) => ({
        guid: generateId(),
        color: getRandomColor(),
        title: s.transitionFieldName,
        value: s.totalDuration,
        tooltip: `${s.transitionFieldName}: ${this.$options.filters.duration(
          s.totalDuration
        )}`,
        width: `${getWidth(s.totalDuration)}%`,
      }))
    },
  },
}
</script>

<style lang="less" scoped>
.stacked-bar {
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  // background: chartreuse;
}
</style>
