import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      breadcrumbKey(route, translator, pluralTranslator) {
        return translator('organization')
      },
      nonLinked: true,
    },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "support-console" */ './views/support-console'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('application_maintenance')}`
          },
        },
      },
    ],
  },
]
