<template>
  <FlotoForm class="flex flex-col flex-1" @submit="handleSubmit">
    <MRow>
      <MCol :size="6">{{ count }}</MCol>
      <MCol v-if="!disabled" :size="6" class="text-right">
        <MButton
          shape="circle"
          :shadow="false"
          variant="transparent"
          type="submit"
        >
          <MIcon
            name="check-circle"
            size="lg"
            class="text-secondary-green"
          ></MIcon>
        </MButton>
        <MButton
          shape="circle"
          :shadow="false"
          variant="transparent"
          @click="$emit('cancel')"
        >
          <MIcon
            name="times-circle"
            size="lg"
            class="text-netural-lightest"
          ></MIcon>
        </MButton>
      </MCol>
    </MRow>
    <MRow>
      <MCol :size="6" class="pr-0">
        <FlotoFormItem
          :label="`${$t('start')} ${$t('date')}`"
          :rules="{
            required: true,
            // eslint-disable-next-line
            min_date: resource.rollOutDateStartTime,
            // eslint-disable-next-line
            max_date: resource.rollOutDateEndTime,
          }"
        >
          <FlotoDatePicker
            v-model="formData.startTime"
            :disabled="disabled"
            :min-date="resource.rollOutDateStartTime"
            :max-date="resource.rollOutDateEndTime"
            :placeholder="$t('select')"
          />
        </FlotoFormItem>
      </MCol>
      <MCol :size="6" class="pr-0">
        <FlotoFormItem
          :label="`${$t('end')} ${$t('date')}`"
          :rules="{
            required: true,
            // eslint-disable-next-line
            min_date: resource.rollOutDateStartTime,
            // eslint-disable-next-line
            max_date: resource.rollOutDateEndTime,
          }"
        >
          <FlotoDatePicker
            v-model="formData.endTime"
            :disabled="disabled"
            :min-date="resource.rollOutDateStartTime"
            :max-date="resource.rollOutDateEndTime"
            :placeholder="$t('select')"
          />
        </FlotoFormItem>
      </MCol>
    </MRow>
    <FlotoFormItem
      v-model="formData.description"
      :disabled="disabled"
      :label="$t('description')"
      :rows="2"
      :placeholder="$t('description_instruction')"
      type="textarea"
    />
    <template v-slot:submit>
      <span />
    </template>
  </FlotoForm>
</template>

<script>
export default {
  name: 'DownTimeForm',
  props: {
    disabled: { type: Boolean, default: false },
    schedule: {
      type: Object,
      default() {
        return {}
      },
    },
    resource: { type: Object, required: true },
    count: { type: Number, default: undefined },
  },
  data() {
    return {
      formData: { ...this.schedule },
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.formData)
    },
  },
}
</script>
