<template>
  <RequestKanbanView
    v-if="view === 'kanban'"
    :dashboard-module-name="dashboardModuleName"
    :default-qualifications="defaultQualifications"
    :search-title="searchTitle"
    :back-link="backLink"
    @viewChange="$emit('viewChange', $event)"
  />
  <FlotoCrudContainer
    v-else
    ref="crudContainerRef"
    :fetch-fn="fetchResources"
    :update-fn="updateFn"
    :show-no-data="false"
    :delete-fn="deleteFn"
    :immediate="fetchImmediate"
    :module-name="moduleName"
    :resource-name="resourceName"
    :create-fn="createFn"
    :page="currentPage"
    v-bind="$attrs"
    class="h-100 resource-list-table-layout"
    @select-item="handleItemSelected"
    @pageChange="$emit('pageChange', $event)"
    @items-received="handleNewItemsReceived"
  >
    <template
      v-slot:add-controls="{
        refreshList,
        create,
        activeItem,
        activateItem,
        items,
        resetActiveItem,
      }"
    >
      <FlotoPageHeader :back-link="backLink">
        <template v-slot:title>
          <slot name="title"></slot>
        </template>
        <template v-slot:title-append>
          <slot name="search-selector" :refresh-list="refreshList">
            <FlotoSearchSelector
              id="search-selector"
              :module-name="moduleName"
              allow-pin
              persist-search
              @change="handleSearchChange($event, refreshList)"
            />
          </slot>
        </template>
        <template v-slot:after-title>
          <slot name="actions" :create="create" :refresh-list="refreshList" />
          <MCol auto-size class="flex items-center text-right justify-end">
            <slot
              name="grid-actions-before"
              :create="create"
              :refresh-list="refreshList"
              :items-length="items.length > 0"
            />
            <MTooltip
              v-if="
                allowSplitView &&
                !isArchivedQualExist(currentSelectedSearch.qualifications)
              "
              placement="topRight"
            >
              <template v-slot:trigger>
                <MButton
                  shape="circle"
                  variant="neutral-lighter"
                  :shadow="false"
                  class="mx-1"
                  @click="
                    (e) => {
                      $emit('viewChange', view === 'split' ? 'list' : 'split')
                      e.target.blur()
                      $nextTick(() => {
                        if (activeItem && view === 'list') {
                          resetActiveItem()
                        }
                      })
                    }
                  "
                >
                  <MIcon :name="view === 'split' ? 'list' : 'split'" />
                </MButton>
              </template>
              {{ $t(view === 'split' ? 'list_view' : 'split_view') }}
            </MTooltip>
            <MTooltip
              v-if="
                allowCalendarView &&
                !isArchivedQualExist(currentSelectedSearch.qualifications)
              "
              placement="topRight"
            >
              <template v-slot:trigger>
                <MButton
                  shape="circle"
                  variant="neutral-lighter"
                  :shadow="false"
                  class="mx-1"
                  @click="
                    (e) => {
                      $emit(
                        'viewChange',
                        view === 'calendar' ? 'list' : 'calendar'
                      )
                      e.target.blur()
                      $nextTick(() => {
                        if (activeItem && view === 'list') {
                          resetActiveItem()
                        }
                      })
                    }
                  "
                >
                  <MIcon
                    :name="view === 'calendar' ? 'list' : 'calendar-alt'"
                  />
                </MButton>
              </template>
              {{ $t(view === 'calendar' ? 'list_view' : 'calendar_view') }}
            </MTooltip>
            <MTooltip v-if="allowKanbanView" placement="topRight">
              <template v-slot:trigger>
                <MButton
                  id="kanban-btn"
                  shape="circle"
                  variant="neutral-lighter"
                  :shadow="false"
                  class="mx-1"
                  @click="
                    (e) => {
                      $emit('viewChange', 'kanban')
                      e.target.blur()
                    }
                  "
                >
                  <MIcon name="columns" />
                </MButton>
              </template>
              {{ $tc('kanban') }} {{ $tc('view') }}
            </MTooltip>
            <slot name="grid-actions-after" :create="create" />
          </MCol>
        </template>
      </FlotoPageHeader>
      <MRow class="mb-2">
        <MCol>
          <slot name="search" :refresh-list="refreshList">
            <FlotoSearchBar
              id="search-bar"
              :module-name="moduleName"
              :persist-criteria="true"
              :default-value="currentSelectedSearch.qualifications"
              @change="refreshList"
            />
          </slot>
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:before-list>
      <slot name="bulk-update-error" />
    </template>
    <template v-slot:list-items="listSlotData">
      <div v-if="view !== 'calendar'" class="w-full flex flex-col flex-1">
        <MRow class="min-h-0 flex-no-wrap flex-1" :gutter="0">
          <MCol
            v-bind="view === 'split' ? { xxl: 3, xl: 3, md: 4 } : { size: 0 }"
            class="flex flex-col overflow-hidden"
            :class="{ 'pr-1': view === 'split' }"
          >
            <FlotoFixedView :no-height="listSlotData.items.length !== 0">
              <div v-if="view === 'split'">
                <div
                  class="py-3 flex items-center"
                  :class="{
                    'bulk-action-header px-2':
                      listSlotData.selectedItemIds.length,
                  }"
                >
                  <MTooltip v-if="listSlotData.selectedItemIds.length">
                    <template v-slot:trigger>
                      <a
                        class="mr-2"
                        @click="listSlotData.toggleSelectAll(false)"
                      >
                        <MIcon name="minus-square" size="lg" />
                      </a>
                    </template>
                    {{ $t('unselect') }} {{ $t('all') }}
                  </MTooltip>
                  <slot
                    :name="
                      listSlotData.selectedItemIds.length
                        ? 'bulk-actions'
                        : 'sort-controls'
                    "
                    :sorted-column="listSlotData.sortedColumn"
                    :apply-sort="listSlotData.applySort"
                    :refresh-list="listSlotData.reset"
                    :toggle-select-all="listSlotData.toggleSelectAll"
                    :selected-item-ids="listSlotData.selectedItemIds"
                    :items="listSlotData.items"
                  >
                  </slot>
                </div>
              </div>
              <FlotoScrollView>
                <div
                  class="flex flex-col min-w-0"
                  :class="{
                    'flex-1': listSlotData.items.length === 0,
                  }"
                >
                  <ActiveTechniciansProvider>
                    <SuggestedFieldValuesProvider
                      :module-name="moduleName"
                      :resource-list="listSlotData.items"
                      check-listing-columns-condition
                    >
                      <ListTable
                        v-if="listSlotData.items.length >= 0 || view === 'list'"
                        :key="`${columns.length}`"
                        :shrink-table="false"
                        :selectable="selectable"
                        :resource-name="resourceName"
                        :columns="columns"
                        :view="view"
                        :show-no-data="view !== 'split'"
                        v-bind="listSlotData"
                        @update="listSlotData.update"
                        @item-click="listSlotData.activateItem"
                      >
                        <template v-slot:row="rowSlotData">
                          <slot name="row" v-bind="rowSlotData" />
                        </template>
                      </ListTable>
                    </SuggestedFieldValuesProvider>
                  </ActiveTechniciansProvider>
                  <FlotoNoData
                    v-if="listSlotData.items.length === 0 && view === 'split'"
                  />
                </div>
              </FlotoScrollView>
              <template
                v-if="view === 'split' && listSlotData.items.length !== 0"
              >
                <MDivider class="my-1" />
                <div class="text-right mb-1">
                  <div class="inline-flex">
                    <MPagination
                      v-model="listSlotData.pageInfo.current"
                      :hide-on-single-page="false"
                      size="small"
                      :page-size="listSlotData.pageInfo.pageSize"
                      show-size-changer
                      :total="listSlotData.pageInfo.total"
                      @change="listSlotData.navigateToPage"
                      @update:page-size="listSlotData.changePageSize"
                    >
                      <template v-slot="slotData">
                        <span>{{ slotData.value }} / {{ $tc('page') }}</span>
                      </template>
                    </MPagination>
                  </div>
                </div>
              </template>
            </FlotoFixedView>
          </MCol>
          <MCol
            v-if="view === 'split'"
            style="position: relative"
            v-bind="view === 'split' ? { xxl: 9, xl: 9, md: 8 } : { size: 0 }"
            class="flex flex-col left-shadow-only overflow-hidden relative"
          >
            <!-- @TODO remove absolute hack to fix content cut when shortcut is used to open reply box in preview mode -->
            <div class="absolute flex flex-1 w-full h-full" style="top: 15px">
              <FlotoFixedView>
                <slot
                  v-if="listSlotData.activeItem"
                  name="detail"
                  :replace-item="listSlotData.replaceItem"
                  :item="listSlotData.activeItem"
                ></slot>
                <FlotoNoData v-else />
              </FlotoFixedView>
            </div>
          </MCol>
        </MRow>
      </div>
      <div v-else class="w-full flex flex-col flex-1 mt-4 pb-8">
        <ChangeCalendarView v-bind="listSlotData" />
      </div>
    </template>
    <template v-if="view === 'split' || view === 'calendar'" v-slot:pagination>
      <span />
    </template>
    <template
      v-for="(_, name) in availableScopedSlots"
      v-slot:[name]="nestedSlot"
    >
      <slot :name="name" v-bind="nestedSlot" />
    </template>
  </FlotoCrudContainer>
</template>

<script>
import Omit from 'lodash/omit'
import {
  transformSearchCriteriasForServer,
  isArchivedQualExist,
} from '@data/search'
import { PageComputed, PageMethods } from '@state/modules/page'
import ListTable from '@components/table/list-table'
import ChangeCalendarView from './change-calendar-view'
import ActiveTechniciansProvider from '@components/providers/active-technicians-provider'
import SuggestedFieldValuesProvider from '@components/providers/suggested-field-value-provider'
import RequestKanbanView from './request-kanban-view'

export default {
  name: 'ListWithPreviewLayout',
  components: {
    ListTable,
    ChangeCalendarView,
    ActiveTechniciansProvider,
    SuggestedFieldValuesProvider,
    RequestKanbanView,
  },
  props: {
    hideSearchBar: { type: Boolean, default: false },
    fetchImmediate: { type: Boolean, default: false },
    backLink: { type: Object, default: undefined },
    // eslint-disable-next-line
    selectable: { type: Boolean, default: true },
    moduleName: { type: String, required: true },
    resourceName: { type: String, required: true },
    fetchFn: { type: Function, required: true },
    updateFn: { type: Function, required: true },
    deleteFn: { type: Function, default: undefined },
    createFn: { type: Function, default: undefined },
    columns: { type: Array, required: true },
    view: { type: String, default: 'list' },
    // eslint-disable-next-line
    allowSplitView: { type: Boolean, default: true },
    allowCalendarView: { type: Boolean, default: false },
    allowKanbanView: { type: Boolean, default: false },
    defaultQualifications: { type: Object, default: null },
    dashboardModuleName: { type: String, default: null },
    searchTitle: { type: String, default: null },
  },
  data() {
    this.isArchivedQualExist = isArchivedQualExist
    return {
      currentPage: 1,
    }
  },
  computed: {
    ...PageComputed,
    availableScopedSlots() {
      if (this.view === 'split') {
        return Omit(this.$scopedSlots, ['bulk-actions'])
      }
      return this.$scopedSlots
    },
    searchCriterias() {
      return this[`${this.moduleName}SearchCriterias`]
    },
    isSearchDirty() {
      return this[`${this.moduleName}SearchDirty`]
    },
    currentSelectedSearch() {
      return this[`${this.moduleName}SelectedSearch`] || {}
    },
    ticketPageData() {
      return this[`${this.moduleName}PageData`] || {}
    },
    listSize() {
      if (this.view === 'split') {
        return { xxl: 3, xl: 3, md: 4 }
      }
      return { size: 12 }
    },
    previewSize() {
      if (this.view === 'split') {
        return { xxl: 9, xl: 9, md: 8 }
      }
      return { size: 0 }
    },
  },
  created() {
    if (Object.keys(this.ticketPageData).length > 0) {
      this.currentPage = this.ticketPageData.current
    }
  },
  methods: {
    ...PageMethods,
    handleNewItemsReceived(items) {
      this.setItemIds(items.map(({ id }) => id))
    },
    handleItemSelected(item) {
      if (this.view === 'list') {
        this.$emit('navigate-to-item', item)
      } else {
        this.$nextTick(() => {
          this.$refs.crudContainerRef.setActiveItem(item)
        })
      }
    },
    handleSearchChange(search, refreshList) {
      if (search.systemName && search.systemName.indexOf('archive') >= 0) {
        this.$emit('viewChange', 'list')
      }
      refreshList()
    },
    setItemIds(ids) {
      if (this[`${this.moduleName}SetListIds`]) {
        this[`${this.moduleName}SetListIds`](ids)
      }
    },
    fetchResources(...args) {
      return this.fetchFn(
        // remove qualId argument for searchbyqual api
        // this.currentSelectedSearch.canUpdate === false
        //   ? this.currentSelectedSearch
        //   : {},
        transformSearchCriteriasForServer(
          this.moduleName,
          this.searchCriterias
        ),
        ...args
      ).then((data) => {
        const limit = args[0]
        const offSet = args[1]
        const current = offSet / limit + 1
        if (this[`${this.moduleName}SetPageData`]) {
          this[`${this.moduleName}SetPageData`]({
            current,
          })
        }
        return data
      })
    },
    refresh() {
      if (this.$refs.crudContainerRef) {
        this.$refs.crudContainerRef.refresh()
      }
    },
  },
}
</script>

<style lang="less">
.item-list-header-row {
  @apply text-neutral;
}

.item-list-row {
  @apply py-4;
}

.resource-list-table-layout {
  .@{ant-prefix}-pagination {
    @apply flex;
  }
  .@{ant-prefix}-pagination-total-text {
    flex-grow: 1;
  }
}
</style>
