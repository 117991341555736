<template>
  <a @click="triggerAction">
    <MIcon name="user-plus" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $t('add') }} {{ $t('watcher') }}
    </span>
    <FlotoDrawerForm
      :open="showForm"
      @submit="handleFormSubmit"
      @cancel="showForm = false"
    >
      <template v-slot:header>
        {{ $t('watcher') }}
      </template>
      <WatcherForm v-model="formData" />
      <template v-slot:actions="{ hide, submit }">
        <MButton
          id="save-btn"
          :loading="processing"
          class="mr-2"
          @click="submit"
          >{{ $t('save') }}</MButton
        >
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>

<script>
import WatcherForm from '@components/watcher/watcher-form'
import { getWatcherApi, updateWatcherApi } from '../../ticket-api'

export default {
  name: 'AddWatcher',
  components: { WatcherForm },
  props: {
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      showForm: false,
      processing: false,
      formData: {},
    }
  },
  created() {
    this.getWatcher()
  },
  methods: {
    getWatcher() {
      return getWatcherApi(this.moduleName, this.resourceId).then((data) => {
        this.formData = data
        return data
      })
    },
    triggerAction() {
      this.$emit('trigger')
      this.getWatcher().then(() => {
        this.showForm = true
      })
    },
    handleFormSubmit() {
      this.processing = true
      updateWatcherApi(this.moduleName, {
        ...this.formData,
        id: this.resourceId,
      })
        .then(() => {
          this.showForm = false
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
