var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',{staticClass:"my-3 items-center",attrs:{"gutter":0}},[(_vm.hiddenFields.indexOf('groupId') === -1)?_c('MCol',{attrs:{"id":"technician-group-picker","size":3}},[_c('FlotoTechnicianGroupPicker',_vm._b({attrs:{"value":_vm.value.groupId,"suggested-value":_vm.suggestedGroupId,"disabled":(_vm.moduleName !== _vm.$constants.CHANGE && _vm.moduleName !== _vm.$constants.RELEASE
          ? _vm.disabled
          : _vm.isTicketClosed) || _vm.singleFieldRulesStateFn('groupId').disable,"placeholder":_vm.disabled ? '---' : undefined,"mandatory-selection":_vm.isRequiredFn('groupId')},on:{"change":function($event){return _vm.handleChange({ groupId: $event })}},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"disabled":(_vm.moduleName !== _vm.$constants.CHANGE &&
            _vm.moduleName !== _vm.$constants.RELEASE
              ? _vm.disabled
              : _vm.isTicketClosed) || _vm.singleFieldRulesStateFn('groupId').disable,"required":_vm.isRequiredFn('groupId'),"lable":_vm.$tc('technician_group', 2),"icon-name":"users"}},'FlotoDropdownTrigger',slotData,false))]}}],null,false,2401230853)},'FlotoTechnicianGroupPicker',{
        hiddenOptionsKeys:
          _vm.singleFieldRulesStateFn('groupId').hiddenOptionsKeys || [],
        visibleOptionsKeys:
          _vm.singleFieldRulesStateFn('groupId').visibleOptionsKeys || [],
      },false))],1):_vm._e(),(_vm.hiddenFields.indexOf('technicianId') === -1)?_c('MCol',{attrs:{"id":"technician-picker","size":3}},[_c('FlotoTechnicianPicker',_vm._b({attrs:{"size":"default","value":_vm.value.technicianId,"module-name":_vm.moduleName,"disabled":(_vm.moduleName !== _vm.$constants.CHANGE && _vm.moduleName !== _vm.$constants.RELEASE
          ? _vm.disabled
          : _vm.isTicketClosed) || _vm.singleFieldRulesStateFn('technicianId').disable,"group-id":_vm.value.groupId,"mandatory-selection":_vm.isRequiredFn('technicianId'),"change-technician-on-group-change":""},on:{"change":function($event){return _vm.handleChange({ technicianId: $event })}},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"disabled":(_vm.moduleName !== _vm.$constants.CHANGE &&
            _vm.moduleName !== _vm.$constants.RELEASE
              ? _vm.disabled
              : _vm.isTicketClosed) ||
            _vm.singleFieldRulesStateFn('technicianId').disable,"required":_vm.isRequiredFn('technicianId'),"lable":_vm.$tc('assignee'),"icon-name":"vector"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlotoUserAvatar',{style:({
                minWidth: ((24) + "px"),
              }),attrs:{"size":34,"avatar":(slotData.currentItem || {}).avatar}})]},proxy:true}],null,true)},'FlotoDropdownTrigger',slotData,false))]}}],null,false,1839177731)},'FlotoTechnicianPicker',{
        hiddenOptionsKeys:
          _vm.singleFieldRulesStateFn('technicianId').hiddenOptionsKeys || [],
        visibleOptionsKeys:
          _vm.singleFieldRulesStateFn('technicianId').visibleOptionsKeys || [],
      },false))],1):_vm._e(),_c('MCol',{attrs:{"id":"due-date-picker","size":3}},[_c('FlotoDueDatePicker',{attrs:{"value":_vm.value.dueBy,"disabled":_vm.isDueByDisabled,"module-name":_vm.moduleName,"status-id":_vm.resource.statusId,"icon-size":"2x","as-dropdown":""},on:{"change":function($event){return _vm.handleChange({ dueBy: $event })}},scopedSlots:_vm._u([{key:"after-text",fn:function(){return [_c('div',{staticClass:"w-full text-ellipsis"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.dueByComputed))+" ")])]},proxy:true}])})],1),(_vm.moduleName === _vm.$constants.REQUEST)?_c('MCol',{attrs:{"id":"ola-due-date-picker","size":3}},[_c('FlotoDueDatePicker',{attrs:{"value":_vm.value.olaDueBy,"disabled":"","module-name":_vm.moduleName,"status-id":_vm.resource.statusId,"icon-size":"2x","prefix":"ola","as-dropdown":""},scopedSlots:_vm._u([{key:"after-text",fn:function(){return [_c('div',{staticClass:"w-full"},[_vm._v(" "+_vm._s(_vm._f("datetime")(_vm.olaDueByComputed))+" ")])]},proxy:true}],null,false,1512196035)})],1):_vm._e(),(
      _vm.moduleName === _vm.$constants.CHANGE &&
      _vm.hiddenFields.indexOf('riskTypeId') === -1
    )?_c('MCol',{attrs:{"id":"risk-type-picker","size":3}},[_c('FlotoRiskTypePicker',_vm._b({attrs:{"value":_vm.value.riskTypeId,"disabled":_vm.disabled || _vm.singleFieldRulesStateFn('riskTypeId').disable,"placeholder":_vm.disabled ? '---' : undefined},on:{"change":function($event){return _vm.handleChange({ riskTypeId: $event })}},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"disabled":_vm.disabled || _vm.singleFieldRulesStateFn('riskTypeId').disable,"lable":_vm.$tc('change_risk'),"icon-name":"exclamation-circle"}},'FlotoDropdownTrigger',slotData,false))]}}],null,false,276306617)},'FlotoRiskTypePicker',{
        hiddenOptionsKeys:
          _vm.singleFieldRulesStateFn('riskTypeId').hiddenOptionsKeys || [],
        visibleOptionsKeys:
          _vm.singleFieldRulesStateFn('riskTypeId').visibleOptionsKeys || [],
      },false))],1):_vm._e(),(
      _vm.moduleName === _vm.$constants.RELEASE &&
      _vm.hiddenFields.indexOf('riskTypeId') === -1
    )?_c('MCol',{attrs:{"id":"release-risk-type-picker","size":3}},[_c('ReleaseRiskTypePicker',{attrs:{"value":_vm.value.riskTypeId,"placeholder":_vm.disabled ? '---' : undefined},on:{"change":function($event){return _vm.handleChange({ riskTypeId: $event })}},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"disabled":_vm.disabled,"lable":_vm.$tc('release_risk'),"icon-name":"exclamation-circle"}},'FlotoDropdownTrigger',slotData,false))]}}],null,false,3316828641)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }